mat-timepicker-dialog {
  .mat-mdc-dialog-content {
    padding: 1rem 0 !important;
  }

  .header {
    background: var(--uw-color-primary) !important;
  }

  .pointer-container.animated-pointer {
    background: var(--uw-color-primary) !important;
  }

  .inner-dot {
    height: 11px !important;
    width: 11px !important;
    left: -5px !important;
    top: -5px !important;
  }

  .inner-dot,
  .outer-dot {
    transform: none !important;
    background: var(--uw-color-primary) !important;
    padding: 0;
  }

  .number.selected {
    color: var(--uw-color-white) !important;
  }

  .number {
    font-size: 16px !important;
  }
}
