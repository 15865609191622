.mat-expansion-panel {
  background: transparent !important;

  &:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }

  &:first-child {
    border-top-left-radius: var(--uw-border-radius) !important;
    border-top-right-radius: var(--uw-border-radius) !important;
  }

  &:last-child {
    border-bottom-left-radius: var(--uw-border-radius) !important;
    border-bottom-right-radius: var(--uw-border-radius) !important;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--uw-border-color);
    border-radius: 0;
  }

  .mat-expansion-panel-header-title {
    font-weight: 500;
    color: var(--uw-card-title-color);

    mat-icon {
      color: var(--uw-card-title-color);
    }
  }
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: var(--uw-card-title-color);
}

.mat-expansion-indicator {
  display: flex;
}

.details-card-content-wrapper {
  color: var(--uw-color-filled-text);
}

.main-sidenav-content {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
    .mat-expansion-panel-header:hover {
    background: transparent;
  }
}

.mat-expansion-panel--compact {
  .mat-expansion-panel-header {
    padding: 0 1rem;
    height: 3rem;
  }

  .mat-expansion-panel-header-title {
    font-size: 0.8rem;
  }

  .mat-expansion-panel-body {
    padding: 0 1rem 0;
  }
}
