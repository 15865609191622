.mat-icon-no-color {
  color: var(--uw-icon-color);

  &[color="primary"] {
    color: var(--uw-color-primary);
  }

  &[color="primary-gradient"] {
    color: var(--uw-color-primary-gradient);
  }
}

.mat-icon {
  &--xl {
    font-size: 36px !important;
    width: 36px !important;
    height: 36px !important;
  }

  &--lg {
    font-size: 32px !important;
    width: 32px !important;
    height: 32px !important;
  }

  &--md {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }

  &--small {
    font-size: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }

  &--sm {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
  }

  &--xs {
    font-size: 1rem !important;
    width: 1rem !important;
    height: 1rem !important;
  }
}

/**
  * https://www.svgrepo.com/collection/solar-outline-icons/
  * adjust settings to fit our own icons
  */
.mat-icon[data-mat-icon-name^="soi-"] {
  stroke: currentColor;
  stroke-width: 0.3;
}
