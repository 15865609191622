// Remove input number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: vertical !important;
}

// Outlined form field styles
.mdc-text-field--outlined {
  background-color: var(--uw-text-background) !important;
}

// Filled form field styles
.mdc-text-field--filled {
  --mdc-filled-text-field-focus-active-indicator-color: var(
    --theme-primary-500
  ) !important;

  &.mdc-text-field--focused {
    .mat-mdc-form-field-focus-overlay {
      display: none;
    }
  }

  &:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: var(--uw-border-color) !important;
  }
}

// Utils
.mat-form-field {
  &--no-hint {
    .mat-mdc-form-field-bottom-align {
      display: none;
    }
  }

  &--transparent {
    background-color: transparent;

    // mdc css class
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-text-field--filled {
      .mdc-line-ripple:before {
        border-bottom: none;
      }
    }

    &:hover,
    &:focus {
      .mat-mdc-text-field-wrapper {
        background: transparent;

        .mat-mdc-form-field-focus-overlay {
          background: transparent;
        }
      }
    }

    .mdc-text-field {
      background-color: transparent !important;
    }

    .mat-mdc-text-field-wrapper {
      background: transparent;
    }
  }

  &--primary-20 {
    background-color: rgba(var(--uw-color-primary-rgb), 0.1);

    // mdc css class
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-text-field--filled {
      .mdc-line-ripple:before {
        border-bottom: none;
      }
    }

    &:hover,
    &:focus {
      .mat-mdc-text-field-wrapper {
        background: rgba(var(--uw-color-primary-rgb), 0.1);

        .mat-mdc-form-field-focus-overlay {
          background: rgba(var(--uw-color-primary-rgb), 0.1);
        }
      }
    }

    .mdc-text-field {
      background-color: rgba(var(--uw-color-primary-rgb), 0.1) !important;
    }

    .mat-mdc-text-field-wrapper {
      background: rgba(var(--uw-color-primary-rgb), 0.1);
    }
  }

  &--fill-parent {
    height: 100%;

    .mat-mdc-form-field-flex {
      height: 100%;
    }

    .mat-mdc-form-field-infix {
      height: 100%;
    }

    input {
      height: 100% !important;
    }
  }
}

.mat-option--no-pseudo {
  .mdc-list-item__primary-text {
    width: 100%;
    margin-right: 0 !important;
  }
  .mat-pseudo-checkbox {
    display: none;
  }
}

.number-input {
  box-shadow: var(--uw-card-box-shadow);

  &::after {
    background: var(--uw-color-number-gradient);
  }
}

.mat-mdc-form-field-subscript-wrapper {
  .mat-mdc-form-field-bottom-align {
    position: relative;
    top: -3px;
  }
  .mat-mdc-form-field-hint-wrapper {
    padding: 0 12px;
  }
}

// Styles
.mdc-notched-outline {
  --mdc-outlined-text-field-outline-color: var(
    --uw-input-border-color
  ) !important;
}

.mat-mdc-select-panel {
  min-width: 10rem;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 10px !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 10px !important;
}

// Timepicker fix
.mat-input-element {
  outline: none !important;
  background: transparent;
}

// Slim select
.slim-select {
  display: flex;
  width: 100%;
  outline: none;
  background-color: var(--uw-tag-background);
  padding: 0.5rem 0.375rem;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;

  .mat-mdc-select-arrow-wrapper {
    justify-content: center;
    width: 24px;
  }

  &.mat-mdc-select-invalid {
    box-shadow: inset 0px -2px 0 0px var(--theme-warn-500);
  }
}

.slim-select__panel {
  min-width: 10rem;
}

.select-panel {
  min-width: 10rem;
}

.type-select-option {
  .type-color-dot {
    display: inline-block;
    position: absolute;
    top: 15px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .text {
    position: absolute;
    top: 10px;
    left: 36px;
  }
}

// Chebox disabled state
.mdc-checkbox--disabled {
  .mdc-checkbox__background {
    &::before {
      z-index: 1005;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--uw-surface-primary);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='1000' height='1000' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%0A%3E%3Cg%3E%3Cline id='svg_1' y2='1000' x2='0' y1='0' x1='1000' stroke-width='100' stroke='%23aaaaaa' fill='none' /%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

mat-checkbox.idc-show-disabled-value {
  &.mdc-checkbox--disabled {
    .mdc-checkbox__background {
      &::before {
        content: unset !important;
        opacity: 0.5;
      }
    }
  }
}
