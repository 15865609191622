@use "../../functions";

.light-orange {
  --theme-primary-50: #ffede6;
  --theme-primary-100: #ffd2c1;
  --theme-primary-200: #ffb598;
  --theme-primary-300: #ff976e;
  --theme-primary-400: #ff804f;
  --theme-primary-500: #ff6a30;
  --theme-primary-600: #ff622b;
  --theme-primary-700: #ff5724;
  --theme-primary-800: #ff4d1e;
  --theme-primary-900: #ff3c13;
  --theme-primary-A100: #ffffff;
  --theme-primary-A200: #fffbfa;
  --theme-primary-A400: #ffcfc7;
  --theme-primary-A700: #ffb9ad;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #000000;
  --theme-primary-contrast-600: #000000;
  --theme-primary-contrast-700: #000000;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #fcf3e2;
  --theme-accent-100: #f9e2b6;
  --theme-accent-200: #f5cf85;
  --theme-accent-300: #f0bc54;
  --theme-accent-400: #edad2f;
  --theme-accent-500: #ea9f0a;
  --theme-accent-600: #e79709;
  --theme-accent-700: #e48d07;
  --theme-accent-800: #e18305;
  --theme-accent-900: #db7203;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffe5cf;
  --theme-accent-A400: #ffca9c;
  --theme-accent-A700: #ffbc83;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000;
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #ff6a30;
  --uw-color-primary-rgb: #{functions.hex2rgb(#ff6a30)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#ea9f0a)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(90deg, #fa8734 0%, #ff7648 100%);
  --uw-color-primary-gradient-light: linear-gradient(
    90deg,
    rgba(250, 134, 53, 0.15) 0%,
    rgba(255, 119, 71, 0.15) 100%
  );
  --uw-color-accent: #ea9f0a;
  --uw-color-accent-gradient: linear-gradient(90deg, #ffc43f 0%, #fdbd18 100%);
  --uw-color-accent-gradient-light: linear-gradient(
    90deg,
    rgba(255, 196, 65, 0.15) 0%,
    rgba(253, 188, 21, 0.15) 100%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(255, 106, 48, 0.11);
  --uw-button-elevation-primary-color-3: rgba(255, 106, 48, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(234, 159, 10, 0.11);
  --uw-button-elevation-accent-color-3: rgba(234, 159, 10, 0.3);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);
}
