@use "../../functions";

.dark-grey {
  --theme-primary-50: #e6e6e7;
  --theme-primary-100: #c1c1c2;
  --theme-primary-200: #98989a;
  --theme-primary-300: #6f6f71;
  --theme-primary-400: #6f6f71;
  --theme-primary-500: #6f6f71;
  --theme-primary-600: #2c2c2f;
  --theme-primary-700: #252527;
  --theme-primary-800: #1f1f21;
  --theme-primary-900: #131315;
  --theme-primary-A100: #6969f3;
  --theme-primary-A200: #3939ef;
  --theme-primary-A400: #0000f6;
  --theme-primary-A700: #0000dc;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #000000;
  --theme-primary-contrast-600: #000000;
  --theme-primary-contrast-700: #000000;
  --theme-primary-contrast-800: #000000;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #f3f3f4;
  --theme-accent-100: #e2e2e4;
  --theme-accent-200: #cecfd3;
  --theme-accent-300: #babbc1;
  --theme-accent-400: #acadb3;
  --theme-accent-500: #9d9ea6;
  --theme-accent-600: #030303;
  --theme-accent-700: #8b8c95;
  --theme-accent-800: #81828b;
  --theme-accent-900: #6f707b;
  --theme-accent-A100: #fafbff;
  --theme-accent-A200: #cbcffb;
  --theme-accent-A400: #949dff;
  --theme-accent-A700: #7a85ff;

  --theme-accent-contrast-50: #000;
  --theme-accent-contrast-100: #000;
  --theme-accent-contrast-200: #000;
  --theme-accent-contrast-300: #000;
  --theme-accent-contrast-400: #000;
  --theme-accent-contrast-500: #000;
  --theme-accent-contrast-600: #000;
  --theme-accent-contrast-700: #000;
  --theme-accent-contrast-800: #000;
  --theme-accent-contrast-900: #fff;
  --theme-accent-contrast-A100: #000;
  --theme-accent-contrast-A200: #000;
  --theme-accent-contrast-A400: #000;
  --theme-accent-contrast-A700: #000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: var(--uw-color-background-dark);
  --uw-color-background: var(--uw-color-background-dark);
  --uw-color-primary: #fff;
  --uw-color-primary-rgb: #{functions.hex2rgb(#fff)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#9d9ea6)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(
    86.72deg,
    #222 1.55%,
    #000 98.45%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    85.64deg,
    rgba(255, 255, 255, 0.1) 1.55%,
    rgba(243, 243, 243, 0.1) 98.45%
  );
  --uw-color-accent: #9d9ea6;
  --uw-color-accent-gradient: linear-gradient(
    86.72deg,
    #a2a3aa 1.55%,
    #93959d 98.45%
  );
  --uw-color-accent-gradient-light: linear-gradient(
    85.64deg,
    rgba(162, 163, 170, 0.1) 1.55%,
    rgba(147, 149, 157, 0.1) 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );
  --uw-color-primary-text: #fff;
  --uw-color-secondary-text: #9d9ea6;
  --uw-color-neutral-primary-text: #fff;
  --uw-color-neutral-secondary-text: #b0b5c6;
  --uw-color-tertiary-text-icons: #8e96a5;
  --uw-surface-primary: #2f2f3b;
  --uw-surface-elevated: #363641;
  --uw-border-color: #454551;
  --uw-additional-color: #454551;
  --uw-color-filled-text: #c3c7d4;
  --uw-color-disable: #5f626f;

  // cards
  --uw-card-background-color: var(--uw-surface-primary) !important;
  --uw-card-title-color: var(--uw-color-neutral-primary-text);
  --uw-card-box-shadow: 0 14px 40px rgba(240, 240, 240, 0.04);

  // inputs
  --uw-disabled-inputs: #3a3a43;
  --uw-disable-input-text-color: #90929c;
  --uw-disabled-field-color: var(--uw-border-color);
  --uw-input-text-color: var(--uw-color-tertiary-text-icons);
  --uw-input-text-filled-color: var(--uw-color-filled-text);
  --uw-input-border-color: var(--uw-border-color);
  --uw-input-untransparent-bg: #3a3a46;
  --uw-tag-background: #3e3e54;
  --uw-tag-color: #c3c7d4;

  // buttons
  --uw-disable-button-color: var(--uw-color-neutral-secondary-text);
  --uw-fab-button-color: transparent;
  --uw-button-text-color: var(--uw-color-neutral-primary-text);
  --uw-raised-button-color: var(--uw-color-neutral-primary-text);

  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: #ffffff52;
  --uw-button-elevation-primary-color-3: #ffffff52;

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: #9d9ea652;
  --uw-button-elevation-accent-color-3: #9d9ea652;

  // text
  --uw-text-primary: var(--uw-color-neutral-primary-text);
  --uw-text-secondary: var(--uw-color-neutral-secondary-text);
  --uw-text-color: var(--uw-color-neutral-secondary-text);
  --uw-text-background: var(--uw-background-color);

  // toolbar
  --uw-toolbar-border-color: var(--uw-border-color);
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // tab
  --uw-tab-color: var(--uw-background-color);
  --uw-tab-active-color: var(--uw-color-primary);
  --uw-tab-label-color: var(--uw-text-color);

  // list
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-list-item-bg-hover: var(--uw-border-color);
  --uw-selected-list-color: #333340;

  // divider
  --uw-divider-color: var(--uw-border-color);

  // icons
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-icon-bg: var(--uw-background-color);
  --uw-text-icon-default: var(--uw-color-tertiary-text-icons);

  // drawer
  --uw-sidenav-content-color: var(--uw-background-color);
  --uw-drawer-border: var(--uw-border-color);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-background-color);
  --uw-datepicker-text-color: var(--uw-color-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-background-color);

  // dialog
  --uw-dialog-card-background-color: var(--uw-background-color);
  --uw-dialog-box-shadow: 0 10px 30px rgba(240, 136, 1, 0.08);

  // stepper
  --uw-step-header-box-shadow: 0 7px 14px #121219;

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);

  // table
  --uw-table-color: var(--uw-background-color);
  --uw-table-head-text-color: var(--uw-color-neutral-secondary-text);
  --uw-table-body-text-color: var(--uw-color-neutral-primary-text);

  // pagination
  --uw-pagination-arrow: var(--uw-color-neutral-primary-text);
  --uw-pagination-disabled-arrow: var(--uw-color-neutral-secondary-text);

  // ribbon
  --uw-ribbon-color: var(--uw-additional-color);
}
