.row {
  &--rounded {
    border-radius: 8px;
  }

  &--primary {
    background: rgba(var(--uw-color-primary-rgb), 0.08);
    color: var(--uw-primary-warn);
  }

  &--warn {
    background: rgba(var(--uw-color-warn-rgb), 0.08);
    color: var(--uw-color-warn);
  }
}
