@use "@angular/material" as mat;
@use "../core";

.login {
  ::ng-deep {
    .login-header {
      text-align: center;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 60px 0;

      @media screen and (max-height: 700px) {
        padding: 30px 0 60px 0;
      }

      a {
        margin-top: 0;
      }
    }
  }
}

.application {
  border-bottom: 2px solid #eff3fa;
  min-height: 64px;

  .btn {
    background: none;
    box-shadow: none !important;
    height: 100%;

    &:active,
    &:focus,
    &:hover {
      background: none;
      border: none;
      box-shadow: none;
    }
  }

  .header {
    align-items: center;
  }

  .login-header {
    padding-left: 20px;

    img {
      height: 18px;
      width: 100px;
    }
  }

  .application-menu {
    height: 100%;
  }
}

.logo {
  display: flex;
  align-items: center;

  img {
    padding-left: 20px;
    padding-right: 20px;
    height: 18px;

    @media (max-width: 959px) {
      padding-left: 4px;
    }
  }
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid var(--uw-toolbar-border-color) !important;
}

.mobile-tabs {
  border-top: 1px solid var(--uw-toolbar-border-color) !important;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  &.mat-primary {
    .mat-mdc-tab-link {
      color: var(--uw-text-color);
      font-weight: 600;
      min-width: 120px;

      &.mat-focus-indicator {
        border-top-left-radius: var(--uw-border-radius) !important;
        border-top-right-radius: var(--uw-border-radius) !important;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .mdc-tab {
    .mat-ripple {
      border-top-left-radius: var(--uw-border-radius) !important;
      border-top-right-radius: var(--uw-border-radius) !important;
    }
    .mdc-tab__ripple:before {
      border-top-left-radius: var(--uw-border-radius) !important;
      border-top-right-radius: var(--uw-border-radius) !important;
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab-body-wrapper {
    height: 100%;
  }
}

.mat-mdc-tab-header {
  .mdc-tab-indicator {
    border-top-left-radius: var(--uw-border-radius) !important;
    border-top-right-radius: var(--uw-border-radius) !important;
  }
}

.mat-mdc-menu-panel {
  background: var(--uw-card-background-color) !important;
}

.mat-mdc-menu-item {
  color: var(--uw-card-title-color);

  .mat-icon-no-color,
  &-submenu-trigger::after {
    color: var(--uw-card-title-color);
  }
}

.mat-mdc-tab-body {
  animation: fade-out 380ms;
  opacity: 0;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  animation: fade-in 380ms;
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.mat-tab-group--rounded {
  .mat-mdc-tab-body {
    border-radius: var(--uw-border-radius) !important;
  }
}

.mat-tab-group--buttons-row {
  .mat-mdc-tab-header {
    border: 0 !important;
  }

  .mdc-tab {
    padding: 0 4px !important;
    height: auto !important;

    .mdc-tab__ripple,
    .mdc-tab-indicator {
      display: none;
    }
  }

  .mat-mdc-tab-header-pagination {
    border-radius: 0.5rem;
  }

  .mat-mdc-tab-header-pagination-disabled {
    display: none !important;
  }
}
