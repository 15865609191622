@use "../../functions";

.light-grey {
  --theme-primary-50: #e6e6e7;
  --theme-primary-100: #c1c1c2;
  --theme-primary-200: #98989a;
  --theme-primary-300: #6f6f71;
  --theme-primary-400: #505052;
  --theme-primary-500: #313134;
  --theme-primary-600: #2c2c2f;
  --theme-primary-700: #252527;
  --theme-primary-800: #1f1f21;
  --theme-primary-900: #131315;
  --theme-primary-A100: #6969f3;
  --theme-primary-A200: #3939ef;
  --theme-primary-A400: #0000f6;
  --theme-primary-A700: #0000dc;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #ffffff;
  --theme-primary-contrast-400: #ffffff;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #ffffff;
  --theme-primary-contrast-A200: #ffffff;
  --theme-primary-contrast-A400: #ffffff;
  --theme-primary-contrast-A700: #ffffff;

  --theme-accent-50: #efeff0;
  --theme-accent-100: #d6d7da;
  --theme-accent-200: #bbbcc1;
  --theme-accent-300: #9fa1a8;
  --theme-accent-400: #8b8c96;
  --theme-accent-500: #767883;
  --theme-accent-600: #6e707b;
  --theme-accent-700: #636570;
  --theme-accent-800: #595b66;
  --theme-accent-900: #464853;
  --theme-accent-A100: #b7c1fb;
  --theme-accent-A200: #8698f9;
  --theme-accent-A400: #4d69ff;
  --theme-accent-A700: #3453ff;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #ffffff;
  --theme-accent-contrast-600: #ffffff;
  --theme-accent-contrast-700: #ffffff;
  --theme-accent-contrast-800: #ffffff;
  --theme-accent-contrast-900: #ffffff;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #ffffff;
  --theme-accent-contrast-A700: #ffffff;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #313134;
  --uw-color-primary-rgb: #{functions.hex2rgb(#313134)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#767883)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(
    86.72deg,
    #3d3d43 1.55%,
    #313134 98.45%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    85.64deg,
    rgba(61, 61, 67, 0.1) 1.55%,
    rgba(49, 49, 52, 0.1) 98.45%
  );
  --uw-color-accent: #767883;
  --uw-color-accent-gradient: linear-gradient(
    86.72deg,
    #797a7d 1.55%,
    #61636c 98.45%
  );
  --uw-color-accent-gradient-light: linear-gradient(
    85.64deg,
    rgba(121, 122, 125, 0.1) 1.55%,
    rgba(97, 99, 108, 0.1) 98.45%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a7a7a7;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(49, 49, 52, 0.11);
  --uw-button-elevation-primary-color-3: rgba(49, 49, 52, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(118, 120, 131, 0.11);
  --uw-button-elevation-accent-color-3: rgba(118, 120, 131, 0.3);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);

  // icon
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-text-icon-default: var(--uw-color-tertiary-text-icons);

  // table
  --uw-table-head-text-color: var(--uw-color-tertiary-text-icons);

  // inputs
  --uw-input-text-color: var(--uw-color-tertiary-text-icons);
}
