@use "@angular/material" as mat;
@use "../typography-definition";

.mat-mdc-standard-chip {
  line-height: 18px;
}

.mat-chip {
  &--small {
    min-height: 28px !important;
    font-weight: mat.m2-font-weight(
      typography-definition.$application-typography,
      "body-1"
    ) !important;
    font-size: mat.m2-font-size(
      typography-definition.$application-typography,
      "body-1"
    ) !important;
  }

  &--caption {
    --mdc-chip-container-height: 24px !important;
    --mdc-chip-label-text-size: 0.7rem !important;
    --mdc-chip-container-shape-radius: 0.25rem !important;
    --uw-tag-background: transparent;

    border: 1px solid var(--uw-border-color);
    background: var(--uw-tag-background) !important;

    .mat-mdc-chip-action {
      background-color: var(--uw-surface-primary);
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }

  &--action {
    .mdc-evolution-chip__action {
      cursor: pointer;
    }
  }
}

.rounded-chip {
  height: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  border: 2px solid var(--uw-border-color);
  box-sizing: border-box;

  &--danger {
    border: 2px solid var(--theme-warn-400);
  }
}

.mat-mdc-chip {
  .mat-icon {
    color: inherit !important;
  }
}

.mat-mdc-text-field-wrapper {
  --mdc-chip-container-shape-radius: 0.25rem;
}

.mat-mdc-chip-listbox,
.mat-mdc-chip-set {
  .mdc-evolution-chip-set__chips {
    align-items: center;
  }
}
