@use "../../functions";

.light-purple {
  --theme-primary-50: #f3e7fc;
  --theme-primary-100: #e1c4f8;
  --theme-primary-200: #cd9df4;
  --theme-primary-300: #b875ef;
  --theme-primary-400: #a958eb;
  --theme-primary-500: #9a3ae8;
  --theme-primary-600: #9234e5;
  --theme-primary-700: #882ce2;
  --theme-primary-800: #7e25de;
  --theme-primary-900: #6c18d8;
  --theme-primary-A100: #ffffff;
  --theme-primary-A200: #e8d8ff;
  --theme-primary-A400: #c9a5ff;
  --theme-primary-A700: #ba8bff;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #edeeff;
  --theme-accent-100: #d1d5ff;
  --theme-accent-200: #b3baff;
  --theme-accent-300: #949eff;
  --theme-accent-400: #7d89ff;
  --theme-accent-500: #6674ff;
  --theme-accent-600: #5e6cff;
  --theme-accent-700: #5361ff;
  --theme-accent-800: #4957ff;
  --theme-accent-900: #3844ff;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffffff;
  --theme-accent-A400: #e1e3ff;
  --theme-accent-A700: #c8cbff;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #ffffff;
  --theme-accent-contrast-600: #ffffff;
  --theme-accent-contrast-700: #ffffff;
  --theme-accent-contrast-800: #ffffff;
  --theme-accent-contrast-900: #ffffff;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #9a3ae8;
  --uw-color-primary-rgb: #{functions.hex2rgb(#9a3ae8)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#6674ff)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(
    86.72deg,
    #af56f5 1.55%,
    #9847ff 98.45%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    85.64deg,
    rgba(160, 85, 255, 0.1) 1.55%,
    rgba(168, 59, 219, 0.1) 98.45%
  );
  --uw-color-accent: #6674ff;
  --uw-color-accent-gradient: linear-gradient(
    86.72deg,
    #818dff 1.55%,
    #6472f1 98.45%
  );
  --uw-color-accent-gradient-light: linear-gradient(
    85.64deg,
    rgba(129, 141, 255, 0.1) 1.55%,
    rgba(100, 114, 241, 0.1) 98.45%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(154, 58, 232, 0.11);
  --uw-button-elevation-primary-color-3: rgba(154, 58, 232, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(102, 116, 255, 0.11);
  --uw-button-elevation-accent-color-3: rgba(102, 116, 255, 0.3);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);
}
