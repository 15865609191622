text {
  font-family: "Poppins", sans-serif !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-bold {
  font-weight: 600 !important;
}

.text-semibold {
  font-weight: 500 !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.highlight {
  background-color: rgba(200 200 200 / 40%);
}

.text-muted {
  opacity: 0.6;
}

.link {
  color: var(--uw-color-primary);
  transition: 0.2s all;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}
