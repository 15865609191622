@use "@angular/material" as mat;
@use "../core";
@use "../typography-definition";

.mat-stepper-horizontal {
  background: transparent !important;

  .mat-step-header {
    background: var(--uw-tab-color);
    box-shadow: var(--uw-step-header-box-shadow);
    border-radius: var(--uw-button-border-radius) !important;
    height: 26px;
    margin: 10px;

    .mat-step-label {
      @include mat.m2-typography-level(
        typography-definition.$application-typography,
        caption
      );
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: var(--uw-text-upper);
      color: #8c8f9b;

      &.mat-step-label-active {
        color: #8c8f9b;
      }

      &.mat-step-label-selected {
        svg path {
          color: #fff !important;
          fill: #fff !important;
        }
      }
    }

    .mat-step-icon {
      display: none;
    }

    &[aria-selected="true"] {
      background: var(--uw-color-primary-gradient);
      .mat-step-label {
        color: #fff;
      }
    }
  }

  .mat-horizontal-content-container {
    display: none;
  }

  .mat-stepper-horizontal-line {
    border-top-width: 2px;
    border-color: var(--uw-border-color);
    margin: 0 -10px;
    min-width: 28px;
  }
}

.mat-stepper-horizontal {
  &--no-header {
    .mat-horizontal-stepper-header-container {
      position: inherit;
      display: none;
    }
  }

  &--no-scroll {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-x: hidden;

    .mat-horizontal-content-container {
      padding: 0;
      height: 100%;
      overflow-x: hidden;
    }

    .mat-horizontal-stepper-wrapper {
      height: 100%;
    }

    .mat-horizontal-stepper-content:not(
        .mat-horizontal-stepper-content-inactive
      ) {
      height: 100%;
    }
  }
}

.stepper-scrolable-container {
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  }

  .mat-horizontal-content-container {
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: visible !important;
    padding: 0px;
  }

  .mat-horizontal-stepper-header-container {
    position: inherit;
    display: none;
  }

  .mat-horizontal-content-container {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .mat-horizontal-stepper-content:not(
      .mat-horizontal-stepper-content-inactive
    ) {
    height: 100%;
  }
}

.mat-stepper--fade {
  .mat-horizontal-stepper-content {
    animation: fade-in 380ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &.mat-horizontal-stepper-content-inactive {
      animation: none !important;
    }
  }
}
