// mat-ext-button-loading-spinner

@keyframes three-quarters {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.button-is-loading {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  .button-is-loading-spinner {
    animation: three-quarters 1.25s infinite linear;
    border: 4px solid #3f51b5;
    border-right-color: transparent;
    border-radius: 100%;
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    transition:
      margin 0.4s ease-out,
      opacity 0.2s ease-out;
    margin-left: -22px;
    opacity: 0;
    vertical-align: middle;
  }

  &.button-is-loading {
    .button-is-loading-spinner {
      transition:
        margin 0.2s ease-in,
        opacity 0.4s ease-in;
      margin-left: 10px;
      opacity: 1;
      // display: inline-block;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    // background-color: #424242;
    // color: white !important;
  }
}

.display {
  display: flex;
  margin: 2rem 1rem;
}

// ngx-spinner border radius

mat-card {
  ngx-spinner.square-top {
    div {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  ngx-spinner.square-bottom {
    div {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.spinner-rounded-lg {
  .ngx-spinner-overlay {
    border-radius: 0.5rem;
  }
}
