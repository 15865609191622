mat-form-field.phone-number-input
  div.mat-form-field-wrapper
  div.mat-form-field-flex
  div
  div.mat-form-field-outline-start {
  min-width: 90px;
}

mat-form-field.phone-number-input
  div.mat-form-field-wrapper
  div.mat-form-field-flex
  div
  div.mat-form-field-outline-gap {
  min-width: 120px;
}

mat-form-field .mat-mdc-form-field-icon-suffix {
  display: flex;
}
