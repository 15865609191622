.mat-ext-signature-pad-container {
  border-color: var(--uw-input-border-color);
  border-radius: var(--uw-input-radius);
  border-width: 2px;
  border-style: solid;
  background-color: var(--uw-color-white);
  margin-left: 3px;
}

.mat-ext-signature-pad-container.disabled {
  border-color: var(--uw-disabled-inputs);
  color: var(--uw-color-background);
}
