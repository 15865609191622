ng-lottie.size-md {
  max-width: 550px;

  @media (max-width: 500px) {
    ng-lottie {
      max-width: 335px;
    }
  }

  @media (max-height: 800px) {
    ng-lottie {
      max-width: 500px;
    }
  }

  @media (max-height: 690px) {
    ng-lottie {
      max-width: 400px;
    }
  }

  @media (max-height: 640px) {
    ng-lottie {
      max-width: 335px;
    }
  }

  @media (max-height: 580px) {
    ng-lottie {
      max-width: 290px;
    }
  }
}
