@use "sass:meta";

$is-roboto-loaded: true;

@import "@syncfusion/ej2-icons/styles/material.css";
@import "@syncfusion/ej2-base/styles/material-definition";
@import "@syncfusion/ej2-base/styles/all";
@import "@syncfusion/ej2-layouts/styles/material.css";
@import "@syncfusion/ej2-image-editor/styles/material.css";
@import "@syncfusion/ej2-pdfviewer/styles/material.css";
@tailwind base;

// @tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
.ovf-auto {
  overflow: auto;
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  touch-action: manipulation;
}

html {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

body {
  overflow: hidden;
}

mat-clock {
  .number {
    left: calc(50% - 22px) !important;
  }
}

.static-blurred-backdrop {
  position: fixed;
  inset: 0;
  z-index: 700;
  backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}

.content-container {
  height: 100%;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-left {
  top: 0;
  left: 0;
}

.ribbon-top-left span {
  right: -50px;
  top: 30px;
  transform: rotate(-45deg);
}

.header-with-avatar {
  justify-content: center;
  margin-bottom: 1rem;

  @media (width <= 959px) {
    margin-top: 13px;
  }

  .mat-mdc-card-title {
    margin: 0 !important;
  }
}

.separate-content {
  position: relative;
  margin-bottom: 1rem;
}

.tab-content {
  max-height: calc(100% - 20px);
}

.lottie {
  width: 100%;
  margin-bottom: 20px;

  @media (width <= 480px) {
    width: 100%;
  }
}

.select-arrow {
  width: 47px;
  height: 44px;
  background: url("./assets/select-arrow.svg");
}

mat-panel-title mat-icon {
  opacity: 0.5;
  margin-right: 10px;
  width: 24px;
}

ngx-spinner[ng-reflect-full-screen="false"] .overlay {
  border-radius: 14px;
}

.mat-mdc-dialog-content {
  ngx-spinner[ng-reflect-full-screen="false"] .overlay {
    width: auto !important;
    left: 16px;
    right: 16px;
  }
}

.ngx-spinner--rounded {
  .ngx-spinner-overlay {
    border-radius: 16px !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// fixes for tailwind

*,
::before,
::after {
  box-sizing: initial;
  border-style: initial;
}

.mat-mdc-menu-panel * {
  box-sizing: border-box;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: revert;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: revert;
  font-weight: revert;
}

.absolute {
  &--fullsize {
    inset: 0;
  }
}

.pb-0 .mat-mdc-dialog-container {
  padding-bottom: 0;
}

// end fixes for tailwind
