@use "@angular/material" as mat;
@use "sass:meta";
@use "core";
@use "components/variables";
@use "themes/light/light-blue";
@use "themes/light/light-green";
@use "themes/light/light-grey";
@use "themes/light/light-orange";
@use "themes/light/light-purple";
@use "themes/light/light-red";
@use "themes/light/light-turquoise";
@use "themes/light/light-yellow";
@use "themes/light/light-azure";
@use "themes/dark/dark-blue";
@use "themes/dark/dark-green";
@use "themes/dark/dark-grey";
@use "themes/dark/dark-orange";
@use "themes/dark/dark-purple";
@use "themes/dark/dark-red";
@use "themes/dark/dark-turquoise";
@use "themes/dark/dark-yellow";
@use "typography-definition";

$dynamic-theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  ),
);

$dynamic-theme-accent: (
  50: var(--theme-accent-50),
  100: var(--theme-accent-100),
  200: var(--theme-accent-200),
  300: var(--theme-accent-300),
  400: var(--theme-accent-400),
  500: var(--theme-accent-500),
  600: var(--theme-accent-600),
  700: var(--theme-accent-700),
  800: var(--theme-accent-800),
  900: var(--theme-accent-900),
  A100: var(--theme-accent-A100),
  A200: var(--theme-accent-A200),
  A400: var(--theme-accent-A400),
  A700: var(--theme-accent-A700),
  contrast: (
    50: var(--theme-accent-contrast-50),
    100: var(--theme-accent-contrast-100),
    200: var(--theme-accent-contrast-200),
    300: var(--theme-accent-contrast-300),
    400: var(--theme-accent-contrast-400),
    500: var(--theme-accent-contrast-500),
    600: var(--theme-accent-contrast-600),
    700: var(--theme-accent-contrast-700),
    800: var(--theme-accent-contrast-800),
    900: var(--theme-accent-contrast-900),
    A100: var(--theme-accent-contrast-A100),
    A200: var(--theme-accent-contrast-A200),
    A400: var(--theme-accent-contrast-A400),
    A700: var(--theme-accent-contrast-A700),
  ),
);

$dynamic-theme-warn: (
  50: var(--theme-warn-50),
  100: var(--theme-warn-100),
  200: var(--theme-warn-200),
  300: var(--theme-warn-300),
  400: var(--theme-warn-400),
  500: var(--theme-warn-500),
  600: var(--theme-warn-600),
  700: var(--theme-warn-700),
  800: var(--theme-warn-800),
  900: var(--theme-warn-900),
  A100: var(--theme-warn-A100),
  A200: var(--theme-warn-A200),
  A400: var(--theme-warn-A400),
  A700: var(--theme-warn-A700),
  contrast: (
    50: var(--theme-warn-contrast-50),
    100: var(--theme-warn-contrast-100),
    200: var(--theme-warn-contrast-200),
    300: var(--theme-warn-contrast-300),
    400: var(--theme-warn-contrast-400),
    500: var(--theme-warn-contrast-500),
    600: var(--theme-warn-contrast-600),
    700: var(--theme-warn-contrast-700),
    800: var(--theme-warn-contrast-800),
    900: var(--theme-warn-contrast-900),
    A100: var(--theme-warn-contrast-A100),
    A200: var(--theme-warn-contrast-A200),
    A400: var(--theme-warn-contrast-A400),
    A700: var(--theme-warn-contrast-A700),
  ),
);

$theme-primary: mat.m2-define-palette($dynamic-theme-primary);
$theme-accent: mat.m2-define-palette($dynamic-theme-accent);
$theme-warn: mat.m2-define-palette($dynamic-theme-warn);

@include mat.all-component-typographies(
  typography-definition.$application-typography
);
@include mat.typography-hierarchy(
  typography-definition.$application-typography
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    density: -1,
    typography: typography-definition.$application-typography,
    background: (
      background: var(--uw-surface-primary),
      dialog: var(--uw-surface-primary),
      card: var(--uw-surface-primary),
    ),
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    density: -1,
    typography: typography-definition.$application-typography,
    background: (
      background: var(--uw-surface-primary),
      dialog: var(--uw-surface-primary),
      card: var(--uw-surface-primary),
    ),
  )
);

.light {
  @include mat.all-component-themes($light-theme);
  @include mat.button-density(0);
  @include mat.form-field-density(-3);
  @include mat.input-density(-3);
  @include mat.select-density(-3);
  @include mat.autocomplete-density(-3);
  @include mat.datepicker-density(-3);
  @include mat.slide-toggle-density(-3);
  @include mat.checkbox-density(-3);
  @include mat.radio-density(-3);
}

.dark {
  @include mat.all-component-themes($dark-theme);
  @include mat.button-density(0);
  @include mat.form-field-density(-3);
  @include mat.input-density(-2e);
  @include mat.select-density(-3);
  @include mat.autocomplete-density(-3);
  @include mat.datepicker-density(-3);
  @include mat.slide-toggle-density(-3);
  @include mat.checkbox-density(-3);
  @include mat.radio-density(-3);
}

@include meta.load-css("components/components");

// 1 rem = 16px by default

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  @include mat.m2-typography-level(
    typography-definition.$application-typography,
    body-1
  );
  margin: 0;
  color: var(--uw-color-neutral-primary-text);
}

strong {
  font-weight: 500;
}
