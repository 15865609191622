.full-height {
  height: 100% !important;
}

.text--danger {
  color: var(--uw-color-danger) !important;
}

.text--warning {
  color: var(--uw-color-warn) !important;
}

.text--truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text--transform-none {
  text-transform: none !important;
}

.bg-default {
  background: var(--uw-background-color);
}

.text-default {
  color: var(--uw-text-icon-default);
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-primary {
  color: var(--uw-text-primary);
}

.text-secondary {
  color: var(--uw-text-secondary);
}

.text-raised-color {
  color: var(--uw-raised-button-color);
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.list-item-active {
  background: rgba(0, 0, 0, 0.075);
}

.w-auto {
  width: auto !important;
}

$tenth: 100;
@each $number in $tenth {
  .w-#{$number} {
    width: $number * 1% !important;
  }
}

.hidden,
[hidden] {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer !important;
}

.overflow-auto {
  overflow: auto;
}

@mixin printable {
  @media print {
    display: block !important;
  }

  @media screen {
    display: none !important;
  }
}

.printable-overlay-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  @include printable();
}

.printable-overlay {
  padding: 24px;
  background: #fff !important;
  @include printable();
}

.print-only {
  @include printable();
}

.spinner {
  animation: spin-animation 0.4s linear infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.qr {
  width: 100px;
  height: 100px;

  &--hidden {
    visibility: hidden;
    position: absolute;
    left: -10000px;
  }
}

.panel--auto-width {
  max-width: 100vw !important;
}

.disclaimer {
  font-size: 0.75rem;
  font-weight: 700;
}

.original-value {
  color: var(--uw-text-icon-default);
  text-decoration: line-through;
}

.tag-cell {
  padding: 4px 8px;
  margin-right: 0.5rem;
  border-radius: 4px;
  font-weight: 600;
}
