.mat-mdc-button-touch-target {
  display: none;
}

.mat-mdc-button-base {
  transition: box-shadow 0.5s;
  padding: 0.5rem 1rem;
  text-transform: var(--uw-text-upper);
}

.mdc-button {
  .mdc-button__label {
    line-height: 1rem;
  }
}

.mat-mdc-button {
  &.mat-primary {
    .mat-ripple-element {
      --mat-text-button-ripple-color: rgba(
        var(--uw-color-primary-rgb),
        0.2
      ) !important;
    }
  }

  &.mat-accent {
    .mat-ripple-element {
      --mat-text-button-ripple-color: rgba(
        var(--uw-color-accent-rgb),
        0.2
      ) !important;
    }
  }

  &.mat-warn {
    .mat-ripple-element {
      --mat-text-button-ripple-color: rgba(
        var(--uw-color-warn-rgb),
        0.2
      ) !important;
    }
  }
}

.mat-mdc-icon-button {
  &.mat-primary {
    .mat-ripple-element {
      --mat-icon-button-ripple-color: rgba(
        var(--uw-color-primary-rgb),
        0.2
      ) !important;
    }
  }

  &.mat-accent {
    .mat-ripple-element {
      --mat-icon-button-ripple-color: rgba(
        var(--uw-color-accent-rgb),
        0.2
      ) !important;
    }
  }

  &.mat-warn {
    .mat-ripple-element {
      --mat-icon-button-ripple-color: rgba(
        var(--uw-color-warn-rgb),
        0.2
      ) !important;
    }
  }

  &.mat-primary,
  &.mat-accent,
  &.mat-warn {
    & > .mat-icon {
      color: inherit;
    }
  }
}

.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
  height: 2.75rem !important;
  min-width: 5rem !important;

  &:disabled {
    background-image: none !important;
  }

  & > .mat-icon {
    color: inherit;
  }
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-container-shape: var(
    --uw-button-border-radius
  ) !important;

  &.mat-primary {
    --mdc-outlined-button-outline-color: var(--theme-primary-500);

    .mat-ripple-element {
      --mat-outlined-button-ripple-color: var(--theme-primary-200);
    }
  }

  &.mat-accent {
    --mdc-outlined-button-outline-color: var(--theme-accent-500);
    .mat-ripple-element {
      --mat-outlined-button-ripple-color: var(--theme-accent-200);
    }
  }

  &.mat-warn {
    --mdc-outlined-button-outline-color: var(--theme-warn-500);
    .mat-ripple-element {
      --mat-outlined-button-ripple-color: var(--theme-warn-200);
    }
  }
}

.mat-mdc-raised-button {
  --mdc-protected-button-container-shape: var(
    --uw-button-border-radius
  ) !important;

  --mdc-protected-button-label-text-color: var(--uw-color-neutral-primary-text);

  &.mat-primary,
  &.mat-accent,
  &.mat-warn {
    --mdc-protected-button-label-text-color: var(
      --uw-raised-button-color
    ) !important;
  }

  &.mat-primary {
    background-image: var(--uw-color-primary-gradient);

    --mdc-protected-button-container-elevation: 0px 3px 1px -2px
        var(--uw-button-elevation-primary-color-1),
      0px 2px 2px 0px var(--uw-button-elevation-primary-color-2),
      0px 1px 5px 0px var(--uw-button-elevation-primary-color-3);

    --mdc-protected-button-hover-container-elevation: 0 2px 4px -1px
        var(--uw-button-elevation-primary-color-1),
      0 4px 5px 1px var(--uw-button-elevation-primary-color-2),
      0 1px 10px 0px var(--uw-button-elevation-primary-color-3);

    --mdc-protected-button-pressed-container-elevation: 0 5px 5px -3px
        var(--uw-button-elevation-primary-color-1),
      0 8px 10px 1px var(--uw-button-elevation-primary-color-2),
      0 3px 14px 2px var(--uw-button-elevation-primary-color-3);
  }

  &.mat-accent {
    background-image: var(--uw-color-accent-gradient);

    --mdc-protected-button-container-elevation: 0px 3px 1px -2px
        var(--uw-button-elevation-accent-color-1),
      0px 2px 2px 0px var(--uw-button-elevation-accent-color-2),
      0px 1px 5px 0px var(--uw-button-elevation-accent-color-3);

    --mdc-protected-button-hover-container-elevation: 0 2px 4px -1px
        var(--uw-button-elevation-accent-color-1),
      0 4px 5px 1px var(--uw-button-elevation-accent-color-2),
      0 1px 10px 0px var(--uw-button-elevation-accent-color-3);

    --mdc-protected-button-pressed-container-elevation: 0 5px 5px -3px
        var(--uw-button-elevation-accent-color-1),
      0 8px 10px 1px var(--uw-button-elevation-accent-color-2),
      0 3px 14px 2px var(--uw-button-elevation-accent-color-3);
  }

  &.mat-warn {
    --mdc-protected-button-container-elevation: 0px 3px 1px -2px
        var(--uw-button-elevation-warn-color-1),
      0px 2px 2px 0px var(--uw-button-elevation-warn-color-2),
      0px 1px 5px 0px var(--uw-button-elevation-warn-color-3);

    --mdc-protected-button-hover-container-elevation: 0 2px 4px -1px
        var(--uw-button-elevation-warn-color-1),
      0 4px 5px 1px var(--uw-button-elevation-warn-color-2),
      0 1px 10px 0px var(--uw-button-elevation-warn-color-3);

    --mdc-protected-button-pressed-container-elevation: 0 5px 5px -3px
        var(--uw-button-elevation-warn-color-1),
      0 8px 10px 1px var(--uw-button-elevation-warn-color-2),
      0 3px 14px 2px var(--uw-button-elevation-warn-color-3);
  }
}

.mat-mdc-unelevated-button {
  --mdc-filled-button-container-shape: var(
    --uw-button-border-radius
  ) !important;

  &.mat-primary,
  &.mat-accent,
  &.mat-warn {
    --mdc-filled-button-label-text-color: var(--uw-color-white) !important;
  }

  &.mat-primary {
    background-image: var(--uw-color-primary-gradient);
  }

  &.mat-accent {
    background-image: var(--uw-color-accent-gradient);
  }
}

// FAB

html .mat-mdc-fab,
html .mdc-fab--mini {
  &:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }

  &:disabled {
    background-image: none !important;
  }

  &.mat-primary:not(.mdc-fab--filled) {
    --mat-fab-foreground-color: var(--theme-primary-500) !important;
    --mat-fab-state-layer-color: var(--theme-primary-500) !important;
    --mdc-fab-container-color: rgba(
      var(--uw-color-primary-rgb),
      0.1
    ) !important;

    --mat-fab-small-foreground-color: var(--theme-primary-500);
    --mat-fab-small-state-layer-color: var(--theme-primary-500);
    --mdc-fab-small-container-color: rgba(var(--uw-color-primary-rgb), 0.1);

    background-image: var(--uw-color-primary-gradient-light);

    > .mat-icon {
      color: inherit;
    }
  }

  &.mat-accent:not(.mdc-fab--filled) {
    --mat-fab-foreground-color: var(--theme-accent-500) !important;
    --mat-fab-state-layer-color: var(--theme-accent-500) !important;
    --mdc-fab-container-color: rgba(var(--uw-color-accent-rgb), 0.1) !important;

    --mat-fab-small-foreground-color: var(--theme-accent-500);
    --mat-fab-small-state-layer-color: var(--theme-accent-500);
    --mdc-fab-small-container-color: rgba(var(--uw-color-accent-rgb), 0.1);

    background-image: var(--uw-color-accent-gradient-light);

    > .mat-icon {
      color: inherit;
    }
  }

  &.mat-warn:not(.mdc-fab--filled) {
    --mat-fab-foreground-color: var(--theme-warn-500) !important;
    --mat-fab-state-layer-color: var(--theme-warn-500) !important;
    --mdc-fab-container-color: rgba(var(--uw-color-warn-rgb), 0.1) !important;

    --mat-fab-small-foreground-color: var(--theme-warn-500);
    --mat-fab-small-state-layer-color: var(--theme-warn-500);
    --mdc-fab-small-container-color: rgba(var(--uw-color-warn-rgb), 0.1);

    > .mat-icon {
      color: inherit;
    }
  }
}

.mdc-fab--filled {
  --mat-fab-foreground-color: var(--uw-color-white) !important;
  --mat-fab-state-layer-color: var(--uw-color-white) !important;

  --mat-fab-small-foreground-color: var(--uw-color-white) !important;
  --mat-fab-small-state-layer-color: var(--uw-color-white) !important;

  &.mat-primary {
    --mdc-fab-container-color: var(--theme-primary-500) !important;
    --mdc-fab-small-container-color: var(--theme-primary-500) !important;
    background-image: var(--uw-color-primary-gradient);

    > .mat-icon {
      color: inherit;
    }
  }

  &.mat-accent {
    --mdc-fab-container-color: var(--theme-accent-500) !important;
    --mdc-fab-small-container-color: var(--theme-accent-500) !important;
    background-image: var(--uw-color-accent-gradient);

    > .mat-icon {
      color: inherit;
    }
  }

  &.mat-warn {
    --mdc-fab-container-color: var(--theme-warn-500) !important;
    --mdc-fab-small-container-color: var(--theme-warn-500) !important;

    > .mat-icon {
      color: inherit;
    }
  }
}

.mdc-fab--outlined:not(.mdc-fab--filled) {
  background-image: none !important;
  --mdc-fab-container-color: transparent !important;
  --mdc-fab-small-container-color: transparent !important;
  border: 1px solid !important;
}

.mdc-fab--boxed {
  width: 2.75rem !important;
  height: 2.75rem !important;
  --mdc-fab-container-shape: 0.25rem;
  --mdc-fab-small-container-shape: 0.25rem;
}

.mdc-fab--mini.mdc-fab--boxed {
  width: 2.5rem;
  height: 2.5rem;
  --mdc-fab-container-shape: 0.25rem;
  --mdc-fab-small-container-shape: 0.25rem;
}

.mdc-button--tone {
  &.mat-primary {
    background-image: var(--uw-color-primary-gradient-light);
    --mdc-filled-button-label-text-color: var(--theme-primary-500) !important;
    --mdc-filled-button-container-color: rgba(
      var(--uw-color-primary-rgb),
      0.1
    ) !important;

    --mdc-outlined-button-label-text-color: var(--theme-primary-500) !important;
    --mdc-outlined-button-container-color: rgba(
      var(--uw-color-primary-rgb),
      0.1
    ) !important;

    --mdc-protected-button-label-text-color: var(
      --theme-primary-500
    ) !important;
    --mdc-protected-button-container-color: rgba(
      var(--uw-color-primary-rgb),
      0.1
    ) !important;
  }

  &.mat-accent {
    background-image: var(--uw-color-accent-gradient-light);
    --mdc-filled-button-label-text-color: var(--theme-accent-500) !important;
    --mdc-filled-button-container-color: rgba(
      var(--uw-color-accent-rgb),
      0.1
    ) !important;

    --mdc-outlined-button-label-text-color: var(--theme-accent-500) !important;
    --mdc-outlined-button-container-color: rgba(
      var(--uw-color-accent-rgb),
      0.1
    ) !important;

    --mdc-protected-button-label-text-color: var(--theme-accent-500) !important;
    --mdc-protected-button-container-color: rgba(
      var(--uw-color-accent-rgb),
      0.1
    ) !important;
  }

  &.mat-warn {
    --mdc-filled-button-label-text-color: var(--theme-warn-500) !important;
    --mdc-filled-button-container-color: rgba(
      var(--uw-color-warn-rgb),
      0.1
    ) !important;

    --mdc-outlined-button-label-text-color: var(--theme-warn-500) !important;
    --mdc-outlined-button-container-color: rgba(
      var(--uw-color-warn-rgb),
      0.1
    ) !important;

    --mdc-protected-button-label-text-color: var(--theme-warn-500) !important;
    --mdc-protected-button-container-color: rgba(
      var(--uw-color-warn-rgb),
      0.1
    ) !important;
  }
}

.buttons-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  transition: 200ms all ease-in-out;

  .buttons-grid__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 33%;
    padding: 1rem;
    background: transparent;
    transition: 0.2s all;
    border-radius: 0.5rem;
    color: var(--uw-color-neutral-primary-text);

    .buttons-grid__item__icon {
      transition: 0.2s all;
    }

    &:hover {
      background: rgba(100, 100, 100, 0.05);

      .buttons-grid__item__icon {
        transform: scale(1.17);
      }
    }

    .buttons-grid__item__title {
      display: block;
      font-weight: 500;
    }
  }
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}
