@use "variables";
@use "utilites/colors";

$table-form-border-radius: 10px;

.form-table {
  width: 100%;
  border-radius: $table-form-border-radius !important;
  border-spacing: 0;
  table-layout: fixed !important;
  border: 2px solid var(--uw-border-color) !important;

  &.mdc-data-table__table {
    min-width: auto !important;
  }

  thead,
  tbody,
  tfoot {
    background: none !important;

    tr {
      td,
      th {
        font-size: 0.875rem !important;
        border-bottom-color: var(--uw-border-color) !important;
        text-align: left;
      }

      td {
        display: table-cell !important;
      }

      th {
        font-weight: 500;
      }

      &:last-child {
        td:last-child {
          border-bottom-right-radius: 8px !important;
        }
      }
    }
  }

  thead {
    tr {
      th {
        border-bottom-width: 2px !important;
        border-bottom-style: solid !important;
      }
    }
  }

  tbody,
  tfoot {
    tr {
      &:not(:last-child) {
        td,
        th {
          border-bottom-width: 2px !important;
          border-bottom-style: solid !important;
        }
      }

      &:last-child {
        td,
        th {
          border-bottom-width: 0 !important;
        }
      }
    }
  }

  tfoot {
    tr {
      td,
      th {
        padding: 6px 12px !important;
        border-top-width: 2px !important;
        border-top-style: solid !important;
        border-top-color: var(--uw-border-color) !important;
      }
    }
  }

  &.mdc-data-table__table {
    border: 2px solid var(--uw-border-color) !important;
  }

  &.mdc-data-table__table.border-0 {
    border: none !important;
  }

  .mat-mdc-cell:not(:first-child),
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    color: inherit !important;
    padding: 0.5rem !important;
    border-left: 2px solid var(--uw-border-color);
  }

  .mat-mdc-header-cell {
    @media only screen and (max-width: 1024px) {
      padding: 0.5rem !important;
    }
  }

  .mat-mdc-chip {
    white-space: nowrap;
  }

  .mat-mdc-header-cell:first-child {
    border-left-width: 0;
  }

  .mat-mdc-row {
    border-bottom-color: var(--uw-border-color) !important;
    border-bottom-width: 2px;
  }

  .table-button {
    height: 32px;
    line-height: 1em;
    width: auto !important;
    padding: 0 1.5rem !important;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  .form-table__input-cell {
    padding: 0 !important;
    position: relative;

    .form-table__input-cell__hint {
      position: absolute;
      bottom: 0;
      left: 0.75rem;
      bottom: 0;
    }

    .mdc-text-field--filled {
      &.mdc-text-field--focused {
        .mat-mdc-form-field-focus-overlay {
          display: block !important;
          opacity: 0.05;
          transition: 0.18 opacity;
        }
      }
    }
  }

  .form-table__chips-cell {
    padding: 0.5rem !important;
  }

  .form-table__cell--disabled {
    position: relative;

    &::before {
      z-index: 1005;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--uw-surface-primary);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='1000' height='1000' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%0A%3E%3Cg%3E%3Cline id='svg_1' y2='1000' x2='0' y1='0' x1='1000' stroke-width='20' stroke='%23e5e9f0' fill='none' /%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  .form-table__input-cell__control {
    min-width: 48px;
    width: 100%;

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-infix {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    @media only screen and (max-width: 1024px) {
      min-width: 40px;
    }

    @media only screen and (max-width: 600px) {
      min-width: 88px;
    }

    ::ng-deep .mat-mdc-select {
      line-height: 1.5em;
    }

    .form-table-control__suffix {
      display: block;
      position: absolute;
      top: -12px;
      right: 0;
    }
  }

  &.show-disabled-data {
    .form-table__cell--disabled::after {
      content: unset !important;
    }
  }

  .form-table__input-cell {
    background: var(--uw-input-untransparent-bg) !important;

    .mat-form-field-invalid {
      background: rgba(var(--uw-color-warn-rgb), 0.1);
      .mat-mdc-text-field-wrapper {
        background: rgba(var(--uw-color-warn-rgb), 0.1) !important;
      }
    }
    .mat-mdc-text-field-wrapper {
      background: var(--uw-input-untransparent-bg);
      border-radius: 0;
    }
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 12px !important;
  }

  td.mat-mdc-cell.form-table__input-cell {
    padding: 0px !important;
  }
}

.suffix-top {
  position: absolute;
  top: -20px;
  right: 8px;
}

.vertical-align-top {
  vertical-align: top;
}

.form-card {
  width: 100%;
  height: 100% !important;

  .form-card__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    padding: 0 !important;
  }

  .mat-mdc-tab-group {
    width: 100%;
    height: 100%;

    .mat-mdc-tab-body {
      padding: 24px;
    }
  }

  &--tabs {
    padding: 0 !important;
  }
}

.full-screen-card {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
