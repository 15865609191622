.mat-datepicker-content {
  border-radius: var(--uw-border-radius) !important;
  background-color: var(--uw-datepicker-bg-color);
  color: var(--uw-datepicker-text-color);
}

.mat-calendar-body-cell {
  padding: 0;
}

.mat-calendar-body-cell-content {
  &.mat-calendar-body-selected {
    background-color: var(--uw-color-primary);
    color: var(--uw-datepicker-selected-text-color);
  }
}

.mat-calendar-header {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    outline: none;
    color: var(--uw-color-primary);
  }
}

.mat-datepicker-toggle {
  &.mat-datepicker-toggle-active {
    color: var(--uw-color-primary);
  }
}

.mat-expansion-panel .mat-expansion-panel-header-title.active {
  color: var(--uw-color-primary);
}

.mat-calendar {
  &-body-label,
  &-body-today:not(.mat-calendar-body-selected),
  &-body-cell-content {
    color: var(--uw-datepicker-text-color);
  }

  &-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
  &-table-header {
    color: var(--uw-datepicker-disabled-text-color);
  }

  &-arrow {
    border-top-color: var(--uw-datepicker-arrow);
  }

  &-body-today:not(.mat-calendar-body-selected) {
    border-color: var(--uw-datepicker-disabled-text-color);
  }

  &-table-header-divider::after {
    background: var(--uw-datepicker-border);
  }
}
