.mat-badge {
  --mat-badge-background-color: var(--uw-color-primary);

  &.mat-badge-warn {
    --mat-badge-background-color: var(--uw-color-warn);
  }

  &.mat-badge-accent {
    --mat-badge-background-color: var(--uw-color-accent);
  }
}

.mat-badge-content {
  color: var(--uw-color-white) !important;
}

.button--low-badge {
  .mat-badge-content {
    top: 6px !important;
  }
}
