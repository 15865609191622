.mat-drawer {
  max-width: 300px;
  width: 100%;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.mat-drawer-content {
  background: var(--uw-card-background-color) !important;
}

.drawer-card {
  display: flex;
  flex-shrink: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px 0px rgba(114, 114, 114, 0.25);
}

.mat-drawer-container {
  &--transparent {
    .mat-drawer-backdrop.mat-drawer-shown {
      background-color: transparent;
    }
  }

  &--idc-blur {
    .mat-drawer-backdrop.mat-drawer-shown {
      backdrop-filter: blur(0.5rem);
      background-color: rgb(200 200 200 / 0.2);
    }
  }
}
