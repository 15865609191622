@use "../../functions";

.light-azure,
.light-azure *,
:root.light-azure {
  // Zeiss Azure
  --theme-primary-50: #e0eefd;
  --theme-primary-100: #b3d5fa;
  --theme-primary-200: #80b9f7;
  --theme-primary-300: #4d9cf4;
  --theme-primary-400: #2687f1;
  --theme-primary-500: #0072ef;
  --theme-primary-600: #006aed;
  --theme-primary-700: #005feb;
  --theme-primary-800: #0055e8;
  --theme-primary-900: #0042e4;
  --theme-primary-A100: #fff;
  --theme-primary-A200: #d8e1ff;
  --theme-primary-A400: #a5b9ff;
  --theme-primary-A700: #8ba5ff;

  --theme-primary-contrast-50: #000;
  --theme-primary-contrast-100: #000;
  --theme-primary-contrast-200: #000;
  --theme-primary-contrast-300: #000;
  --theme-primary-contrast-400: #000;
  --theme-primary-contrast-500: #fff;
  --theme-primary-contrast-600: #fff;
  --theme-primary-contrast-700: #fff;
  --theme-primary-contrast-800: #fff;
  --theme-primary-contrast-900: #fff;
  --theme-primary-contrast-A100: #000;
  --theme-primary-contrast-A200: #000;
  --theme-primary-contrast-A400: #000;
  --theme-primary-contrast-A700: #000;

  --theme-accent-50: #e0f1f9;
  --theme-accent-100: #b3dcf1;
  --theme-accent-200: #80c5e8;
  --theme-accent-300: #4daede;
  --theme-accent-400: #269cd7;
  --theme-accent-500: #008bd0;
  --theme-accent-600: #0083cb;
  --theme-accent-700: #0078c4;
  --theme-accent-800: #006ebe;
  --theme-accent-900: #005bb3;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffffff;
  --theme-accent-A400: #e1e3ff;
  --theme-accent-A700: #c8cbff;

  --theme-accent-contrast-50: #000;
  --theme-accent-contrast-100: #000;
  --theme-accent-contrast-200: #000;
  --theme-accent-contrast-300: #000;
  --theme-accent-contrast-400: #000;
  --theme-accent-contrast-500: #000;
  --theme-accent-contrast-600: #000;
  --theme-accent-contrast-700: #fff;
  --theme-accent-contrast-800: #fff;
  --theme-accent-contrast-900: #fff;
  --theme-accent-contrast-A100: #000;
  --theme-accent-contrast-A200: #000;
  --theme-accent-contrast-A400: #000;
  --theme-accent-contrast-A700: #000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-color-disable: #5f626f;

  --uw-button-border-radius: 3px;
  --uw-color-primary-gradient: #0072ef;
  --uw-color-primary-gradient-light: linear-gradient(
    86.46deg,
    #229afa1a 7.85%,
    #1168f81a 97.37%
  );

  // ZEISS Azure
  --uw-color-primary-rgb: #{functions.hex2rgb(#0072ef)};

  // --uw-color-primary-text: #0072ef;
  --uw-color-primary-text: #0072ef;
  --uw-border-radius: 3px !important;
  --mdc-elevated-card-container-shape: 3px;
  --ag-border-radius: 3px !important;
  --mdc-shape-small: 3px;

  .mdc-fab--boxed {
    --mdc-fab-container-shape: 3px;
  }

  .mdc-fab--mini.mdc-fab--boxed {
    --mdc-fab-container-shape: 3px;
  }
}
