@use "../../functions";

.dark-orange {
  --theme-primary-50: #fff1eb;
  --theme-primary-100: #ffdcce;
  --theme-primary-200: #ffc5ae;
  --theme-primary-300: #ffad8d;
  --theme-primary-400: #ff9c74;
  --theme-primary-500: #ff8a5c;
  --theme-primary-600: #ff8254;
  --theme-primary-700: #ff774a;
  --theme-primary-800: #ff6d41;
  --theme-primary-900: #ff5a30;
  --theme-primary-A100: #ffffff;
  --theme-primary-A200: #ffffff;
  --theme-primary-A400: #ffe2db;
  --theme-primary-A700: #ffcdc2;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #000000;
  --theme-primary-contrast-600: #000000;
  --theme-primary-contrast-700: #000000;
  --theme-primary-contrast-800: #000000;
  --theme-primary-contrast-900: #000000;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #fcf3e2;
  --theme-accent-100: #f9e2b6;
  --theme-accent-200: #f5cf85;
  --theme-accent-300: #f0bc54;
  --theme-accent-400: #edad2f;
  --theme-accent-500: #ea9f0a;
  --theme-accent-600: #e79709;
  --theme-accent-700: #e48d07;
  --theme-accent-800: #e18305;
  --theme-accent-900: #db7203;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffe5cf;
  --theme-accent-A400: #ffca9c;
  --theme-accent-A700: #ffbc83;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000;
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: var(--uw-color-background-dark);
  --uw-color-background: var(--uw-color-background-dark);
  --uw-color-primary: #ff8a5c;
  --uw-color-primary-rgb: #{functions.hex2rgb(#ff8a5c)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#ea9f0a)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(90deg, #fa9d5a 0%, #ff8961 100%);
  --uw-color-primary-gradient-light: linear-gradient(
    90deg,
    rgba(250, 157, 90, 0.12) 0%,
    rgba(255, 137, 97, 0.12) 100%
  );
  --uw-color-accent: #ea9f0a;
  --uw-color-accent-gradient: linear-gradient(90deg, #ffd066 0%, #fdc73d 100%);
  --uw-color-accent-gradient-light: linear-gradient(
    90deg,
    rgba(255, 208, 102, 0.12) 0%,
    rgba(253, 199, 61, 0.12) 100%
  );
  --uw-color-number-gradient: linear-gradient(
    #363641 0%,
    transparent 20%,
    transparent 80%,
    #363641 100%
  );
  --uw-color-primary-text: #ff8a5c;
  --uw-color-secondary-text: #ea9f0a;
  --uw-color-neutral-primary-text: #fff;
  --uw-color-neutral-secondary-text: #b0b5c6;
  --uw-color-tertiary-text-icons: #8e96a5;
  --uw-surface-primary: #2f2f3b;
  --uw-surface-elevated: #363641;
  --uw-border-color: #454551;
  --uw-additional-color: #8e96a5;
  --uw-color-filled-text: #c3c7d4;
  --uw-color-disable: #5f626f;

  // cards
  --uw-card-background-color: var(--uw-surface-primary);
  --uw-card-title-color: var(--uw-color-neutral-primary-text);
  --uw-card-box-shadow: 0 14px 40px rgba(240, 240, 240, 0.04);

  // inputs
  --uw-disabled-inputs: #3a3a43;
  --uw-disable-input-text-color: #90929c;
  --uw-disabled-field-color: var(--uw-border-color);
  --uw-input-text-color: var(--uw-color-tertiary-text-icons);
  --uw-input-text-filled-color: var(--uw-color-filled-text);
  --uw-input-border-color: var(--uw-border-color);
  --uw-input-untransparent-bg: #3a3a46;
  --uw-tag-background: #3e3e54;
  --uw-tag-color: #c3c7d4;

  // buttons
  --uw-disable-button-color: var(--uw-color-neutral-secondary-text);
  --uw-fab-button-color: transparent;
  --uw-button-text-color: var(--uw-color-neutral-primary-text);
  --uw-raised-button-color: var(--uw-color-neutral-primary-text);

  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: #ff8a5c52;
  --uw-button-elevation-primary-color-3: #ff8a5c52;

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: #ea9f0a52;
  --uw-button-elevation-accent-color-3: #ea9f0a52;

  --uw-button-accent-color: var(--uw-color-accent);
  --uw-button-primary-color: var(--uw-color-primary);

  // text
  --uw-text-primary: var(--uw-color-neutral-primary-text);
  --uw-text-secondary: var(--uw-color-neutral-secondary-text);
  --uw-text-color: var(--uw-color-neutral-secondary-text);
  --uw-text-background: var(--uw-background-color);

  // toolbar
  --uw-toolbar-border-color: var(--uw-border-color);
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // tab
  --uw-tab-color: var(--uw-background-color);
  --uw-tab-active-color: var(--uw-color-primary);
  --uw-tab-label-color: var(--uw-text-color);

  // list
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-list-item-bg-hover: var(--uw-border-color);
  --uw-selected-list-color: #333340;

  // divider
  --uw-divider-color: var(--uw-border-color);

  // icons
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-icon-bg: var(--uw-background-color);
  --uw-text-icon-default: var(--uw-color-tertiary-text-icons);

  // drawer
  --uw-sidenav-content-color: var(--uw-background-color);
  --uw-drawer-border: var(--uw-border-color);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-background-color);
  --uw-datepicker-text-color: var(--uw-color-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-background-color);

  // dialog
  --uw-dialog-card-background-color: var(--uw-background-color);
  --uw-dialog-box-shadow: 0 10px 30px rgba(240, 136, 1, 0.08);

  // stepper
  --uw-step-header-box-shadow: 0 7px 14px #121219;

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);

  // table
  --uw-table-color: var(--uw-background-color);
  --uw-table-head-text-color: var(--uw-color-neutral-secondary-text);
  --uw-table-body-text-color: var(--uw-color-neutral-primary-text);

  // pagination
  --uw-pagination-arrow: var(--uw-color-neutral-primary-text);
  --uw-pagination-disabled-arrow: var(--uw-color-neutral-secondary-text);
}
