@use "../../functions";

.light-yellow {
  --theme-primary-50: #fcf3e2;
  --theme-primary-100: #f9e2b6;
  --theme-primary-200: #f5cf85;
  --theme-primary-300: #f0bc54;
  --theme-primary-400: #edad2f;
  --theme-primary-500: #ea9f0a;
  --theme-primary-600: #e79709;
  --theme-primary-700: #e48d07;
  --theme-primary-800: #e18305;
  --theme-primary-900: #db7203;
  --theme-primary-A100: #ffffff;
  --theme-primary-A200: #ffe5cf;
  --theme-primary-A400: #ffca9c;
  --theme-primary-A700: #ffbc83;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #000000;
  --theme-primary-contrast-600: #000000;
  --theme-primary-contrast-700: #000000;
  --theme-primary-contrast-800: #000000;
  --theme-primary-contrast-900: #000000;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #fff3ea;
  --theme-accent-100: #ffe0cb;
  --theme-accent-200: #ffcba9;
  --theme-accent-300: #ffb687;
  --theme-accent-400: #ffa76d;
  --theme-accent-500: #ff9753;
  --theme-accent-600: #ff8f4c;
  --theme-accent-700: #ff8442;
  --theme-accent-800: #ff7a39;
  --theme-accent-900: #ff6929;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #ffffff;
  --theme-accent-A400: #ffe1d6;
  --theme-accent-A700: #ffcfbd;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000;
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #ea9f0a;
  --uw-color-primary-rgb: #{functions.hex2rgb(#ea9f0a)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#ff9753)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(90deg, #ffc545 0%, #fdbb13 100%);
  --uw-color-primary-gradient-light: linear-gradient(
    90deg,
    rgba(255, 196, 63, 0.15) 0%,
    rgba(253, 189, 26, 0.15) 100%
  );
  --uw-color-accent: #ff9753;
  --uw-color-accent-gradient: linear-gradient(
    90deg,
    #ffb07c 0%,
    #ff9753 100%,
    #ff9753 100%
  );
  --uw-color-accent-gradient-light: linear-gradient(
    90deg,
    rgba(255, 175, 124, 0.15) 0%,
    rgba(255, 151, 83, 0.15) 100%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(234, 159, 10, 0.11);
  --uw-button-elevation-primary-color-3: rgba(234, 159, 10, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(255, 151, 83, 0.11);
  --uw-button-elevation-accent-color-3: rgba(255, 151, 83, 0.3);

  --uw-button-primary-color: var(--uw-color-primary);
  --uw-button-accent-color: var(--uw-color-accent);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);

  // table
  --uw-table-head-text-color: var(--uw-color-tertiary-text-icons);
}
