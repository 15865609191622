@use "../typography-definition";
@use "@angular/material" as mat;
@import "@ag-grid-community/styles//ag-grid.css";
@import "@ag-grid-community/styles//ag-theme-material.css";

.mat-mdc-table {
  background: var(--uw-table-color) !important;
  border-radius: 20px;
  border: 2px solid var(--uw-border-color) !important;
  box-sizing: border-box;

  .mat-mdc-header-row {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 2px solid var(--uw-border-color) !important;
  }

  .mat-mdc-header-cell {
    color: var(--uw-table-head-text-color);
  }

  .mat-mdc-row {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-bottom: none;
    }

    .mat-mdc-cell {
      color: var(--uw-text-color);

      &.disabled {
        color: var(--uw-disable-input-text-color);
      }
    }
  }

  .mat-mdc-footer-row {
    background: transparent;
    border-bottom: none;

    .mat-mdc-footer-cell {
      color: var(--uw-table-head-text-color);
    }
  }

  &.plan-table {
    box-shadow: var(--uw-card-box-shadow);

    .mat-mdc-header-cell {
      @include mat.m2-typography-level(
        typography-definition.$application-typography,
        headline-6
      );
      color: var(--uw-color-neutral-primary-text);

      .price-subscription {
        font-weight: normal;
        color: var(--uw-color-disable);
      }
    }

    .mat-mdc-header-row,
    .mat-mdc-row,
    .mat-mdc-footer-row {
      .mat-mdc-cell,
      .mat-mdc-footer-cell,
      .mat-mdc-header-cell {
        border-color: var(--uw-border-color) !important;
      }
    }

    .mat-mdc-row .mat-mdc-cell {
      &.disabled {
        background-color: var(--uw-color-background);
      }
    }
  }
}

.vertical-align-top {
  vertical-align: top !important;
}

.row--danger {
  td {
    background: var(--theme-warn-50) !important;
    color: var(--uw-color-warn) !important;
    font-weight: 500;
  }
}

.cell--danger {
  background: var(--theme-warn-50) !important;
  color: var(--uw-color-warn) !important;
  font-weight: 500;
}

.mat-mdc-table-header {
  background-color: var(--uw-background-color);
}

.mat-mdc-table-total {
  color: var(--uw-text-color);
}

.e-headercelldiv {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.child-grid-action {
  box-shadow: none;
}

.hierarchy-grid {
  .e-detailrow {
    background: var(--uw-color-background);

    .e-detailcell {
      padding: 2rem 1rem 1rem 1rem;

      .e-grid {
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
      }
    }

    .e-table {
      background-color: var(--uw-surface-primary) !important;
    }
  }
}

.link-cell {
  color: var(--uw-color-primary);
  transition: 0.2s all;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

table.product-table {
  width: 100%;
  border: none;
  background: transparent !important;
  --table-border: 2px solid var(--uw-border-color);
  --table-border-radius: 6px;
  --table-cell-padding: 10px 12px;

  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      border-bottom: var(--table-border) !important;
      border-left: var(--table-border);
      padding: var(--table-cell-padding);
      color: inherit !important;

      &:last-of-type {
        border-right: var(--table-border);
      }
    }

    &:first-child {
      .mat-mdc-header-cell {
        border-top: var(--table-border);
      }

      .mat-mdc-header-cell:first-child {
        border-top-left-radius: var(--table-border-radius);
      }

      .mat-mdc-header-cell:last-child {
        border-top-right-radius: var(--table-border-radius);
        border-right: var(--table-border);
      }
    }
  }

  .mat-mdc-row {
    &:last-child {
      .mat-mdc-cell:first-child {
        border-bottom-left-radius: var(--table-border-radius);
      }

      .mat-mdc-cell:last-child {
        border-bottom-right-radius: var(--table-border-radius);
        border-right: var(--table-border);
      }
    }

    .mat-mdc-cell {
      border-bottom: var(--table-border) !important;
      border-left: var(--table-border);
      padding: var(--table-cell-padding);
      width: 10%;
      min-width: 65px;

      &:last-of-type {
        border-right: var(--table-border);
      }

      &:nth-child(1) {
        text-align: center;
        width: 32px;
        min-width: 32px;
      }

      &:nth-child(2) {
        width: 100%;
        vertical-align: center;
      }
    }
  }

  .item-thumbnail {
    border-radius: 8px;
    object-fit: contain;
    width: 65px;
  }

  .item-thumbnail-container {
    display: flex;
    min-width: 65px;
    height: 65px;
    justify-content: center;
    border: 1px solid var(--uw-border-color);
    border-radius: 8px;
    margin-right: 8px;
  }
}

.table-wrapper {
  overflow-y: auto;
  width: 100%;

  th,
  td {
    white-space: nowrap;
  }
}

.table {
  &--stripped {
    width: 100%;
    border-radius: 0px;
    border: none;
    border-spacing: 0px;

    --border-radius: 6px;
    --border-style: 1px solid var(--uw-border-color);

    tr {
      border: none;

      &.mat-mdc-header-row {
        height: unset !important;
      }

      td,
      th {
        padding: 10px;
        font-size: 13px;
        color: var(--uw-color-text-one);
      }
    }

    thead {
      & > tr {
        td,
        th {
          font-weight: 600;
          text-align: left;

          &:first-child {
            border-left: var(--border-style);
          }

          &:last-child {
            border-right: var(--border-style);
          }
        }

        &:first-child {
          td,
          th {
            border-top: var(--border-style);

            &:first-child {
              border-top-left-radius: var(--border-radius);
            }

            &:last-child {
              border-top-right-radius: var(--border-radius);
            }
          }
        }

        &:last-child {
          td,
          th {
            border-bottom: var(--border-style);
          }
        }
      }
    }

    tbody {
      & > tr {
        td,
        th {
          &:first-child {
            border-left: var(--border-style);
          }

          &:last-child {
            border-right: var(--border-style);
          }
        }

        &:last-child {
          td,
          th {
            border-bottom: var(--border-style);

            &:first-child {
              border-bottom-left-radius: var(--border-radius);
            }

            &:last-child {
              border-bottom-right-radius: var(--border-radius);
            }
          }
        }

        &.footer {
          td,
          th {
            border-top: var(--border-style);
          }
        }

        .last-cell {
          border-bottom: var(--border-style);
          border-bottom-right-radius: var(--border-radius);
        }
      }
    }
  }

  &--no-header {
    tbody {
      & > tr:first-child {
        td,
        th {
          border-top: var(--border-style);

          &:first-child {
            border-top-left-radius: var(--border-radius);
          }

          &:last-child {
            border-top-right-radius: var(--border-radius);
          }
        }
      }
    }
  }
}

.ag-theme-idc {
  // Background color
  --ag-subheader-background-color: var(--uw-surface-primary);
  --ag-range-selection-border-color: transparent;
  --ag-background-color: var(--uw-surface-primary);
  --ag-selected-row-background-color: var(--uw-background-color);
  --ag-modal-overlay-background-color: rgba(var(--uw-background-color), 0.66);
  --ag-control-panel-background-color: var(--uw-surface-primary);

  // Foreground
  --ag-foreground-color: var(--uw-color-neutral-primary-text);
  --ag-header-foreground-color: var(--uw-color-neutral-primary-text);
  --ag-header-background-color: var(--uw-surface-primary);
  --ag-material-primary-color: var(--theme-primary-500);
  --ag-checkbox-checked-color: var(--theme-primary-500);
  --ag-secondary-foreground-color: var(--uw-color-neutral-primary-text);
  --ag-disabled-foreground-color: var(--uw-color-neutral-primary-text);
  --ag-chip-background-color: var(--uw-background-color);

  // Hover
  --ag-header-cell-hover-background-color: var(--uw-background-color);
  --ag-row-hover-color: var(--uw-background-color);
  --ag-column-hover-color: rgba(var(--uw-color-primary-rgb), 0.1);

  // Borders
  --ag-borders: solid 2px;
  --ag-borders-color: var(--uw-border-color);
  --ag-border-color: var(--uw-border-color);
  --ag-border-radius: 0.625rem;

  // Fonts
  --ag-font-family: Poppins, sans-serif;

  // Grid size
  --ag-grid-size: 6px;
  &.cells-xl {
    --ag-grid-size: 8px;
  }

  .ag-overlay {
    pointer-events: all !important;
  }

  &.round {
    &--bottom-left {
      & > .ag-root-wrapper {
        border-bottom-left-radius: var(--ag-border-radius);
      }
    }

    &--bottom-right {
      & > .ag-root-wrapper {
        border-bottom-right-radius: var(--ag-border-radius);
      }
    }

    &--top-left {
      & > .ag-root-wrapper {
        border-top-left-radius: var(--ag-border-radius);
      }
    }

    &--top-right {
      & > .ag-root-wrapper {
        border-top-right-radius: var(--ag-border-radius);
      }
    }
  }

  &.full-card-mode {
    & > .ag-root-wrapper {
      border-left: none;
      border-bottom: none;
      border-right: none;
      border-width: 1px;
    }
  }

  &.no-side-actions {
    .ag-side-buttons {
      display: none;
    }
  }

  input.ag-text-field-input {
    &::placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &::-webkit-placeholder,
    &:-ms-input-placeholder {
      color: rgba(var(--ag-foreground-color), 0.85) !important;
    }
  }

  .ag-layout-auto-height {
    .ag-center-cols-container {
      min-height: auto;
    }

    .ag-center-cols-clipper {
      min-height: auto;
    }
  }

  .ag-checkbox-input {
    cursor: pointer;
  }

  .ag-full-width-container {
    .ag-full-width-row {
      display: flex;
      flex-direction: column;
    }
  }

  .ag-selection-checkbox.ag-invisible {
    display: none;
  }

  /*
    * Set to avoid margin right: 16px from ag grid in the iPad
   */
  .ag-pinned-right-cols-container {
    margin-right: 0 !important;
  }

  .ag-header-cell {
    transition: background-color 0s;
  }
}

// Customizations specific for dark themes
.dark {
  .ag-theme-material.ag-theme-idc {
    --ag-checkbox-unchecked-color: #ffffffb3;
  }
}

.idc-table-snack {
  padding: 5px 10px;
  align-self: center;
  /* stylelint-disable-next-line declaration-colon-space-after */
  box-shadow:
    0 -9px 10px rgb(114 114 114 / 0.11),
    0 9px 10px rgb(114 114 114 / 0.11);
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
}
