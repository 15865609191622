.formula {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  .term {
    position: relative;
    display: inline-flex;
    sup {
      position: absolute;
      left: 1em;
    }
    sub {
      padding-top: 8px;
    }
  }

  .mo {
    margin: 0 0.5em;
  }

  .fraction {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .numerator {
    border-bottom: 1px solid black;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .denominator {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
