.light-blue {
  --theme-primary-50: #e4f0fe;
  --theme-primary-100: #bbdafd;
  --theme-primary-200: #8dc2fc;
  --theme-primary-300: #5fa9fb;
  --theme-primary-400: #3d96fa;
  --theme-primary-500: #1b84f9;
  --theme-primary-600: #187cf8;
  --theme-primary-700: #1471f7;
  --theme-primary-800: #1067f6;
  --theme-primary-900: #0854f5;
  --theme-primary-A100: #fff;
  --theme-primary-A200: #eaf0ff;
  --theme-primary-A400: #b7cbff;
  --theme-primary-A700: #9db8ff;
  --theme-primary-contrast-50: #000;
  --theme-primary-contrast-100: #000;
  --theme-primary-contrast-200: #000;
  --theme-primary-contrast-300: #000;
  --theme-primary-contrast-400: #000;
  --theme-primary-contrast-500: #fff;
  --theme-primary-contrast-600: #fff;
  --theme-primary-contrast-700: #fff;
  --theme-primary-contrast-800: #fff;
  --theme-primary-contrast-900: #fff;
  --theme-primary-contrast-A100: #000;
  --theme-primary-contrast-A200: #000;
  --theme-primary-contrast-A400: #000;
  --theme-primary-contrast-A700: #000;
  --theme-accent-50: #edeeff;
  --theme-accent-100: #d1d5ff;
  --theme-accent-200: #b3baff;
  --theme-accent-300: #949eff;
  --theme-accent-400: #7d89ff;
  --theme-accent-500: #6674ff;
  --theme-accent-600: #5e6cff;
  --theme-accent-700: #5361ff;
  --theme-accent-800: #4957ff;
  --theme-accent-900: #3844ff;
  --theme-accent-A100: #fff;
  --theme-accent-A200: #fff;
  --theme-accent-A400: #e1e3ff;
  --theme-accent-A700: #c8cbff;
  --theme-accent-contrast-50: #000;
  --theme-accent-contrast-100: #000;
  --theme-accent-contrast-200: #000;
  --theme-accent-contrast-300: #000;
  --theme-accent-contrast-400: #000;
  --theme-accent-contrast-500: #000;
  --theme-accent-contrast-600: #000;
  --theme-accent-contrast-700: #fff;
  --theme-accent-contrast-800: #fff;
  --theme-accent-contrast-900: #fff;
  --theme-accent-contrast-A100: #000;
  --theme-accent-contrast-A200: #000;
  --theme-accent-contrast-A400: #000;
  --theme-accent-contrast-A700: #000;
  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;

  //  ZEISS Bright Orange Neon
  --theme-warn-500: #e71e1e;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;
  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;
  --uw-color-disable: #5f626f;
}
