@use "../functions";

:root {
  --uw-color-primary-text: #1b84f9;
  --uw-color-primary-rgb: #{functions.hex2rgb(#1b84f9)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#6674ff)};
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-surface-primary: #fff;
  --uw-color-border: #e5e9f0;
  --uw-color-filled-text: #5f626f;
  --uw-color-white: #fff;
  --uw-color-background: #f9fafd;
  --uw-color-background-dark: #242431;
  --uw-color-success: #1e9f96;
  --uw-color-success-light: #5fcb9c;

  // Grey
  --uw-grey-50: #fafafa;
  --uw-grey-100: #f5f5f5;
  --uw-grey-200: #eee;
  --uw-grey-300: #e0e0e0;
  --uw-grey-400: #bdbdbd;
  --uw-grey-500: #9e9e9e;
  --uw-grey-600: #757575;
  --uw-grey-700: #616161;
  --uw-grey-800: #424242;
  --uw-grey-900: #212121;
  --uw-grey-dark: #303030;

  --uw-background-color: var(--uw-color-background);
  --uw-background-disabled: rgba(0, 0, 0, 0.1);
  --uw-color-primary: var(--uw-color-primary-text);
  --uw-color-primary-shade: #1b84f952;
  --uw-color-primary-gradient: linear-gradient(
    86.46deg,
    #229afa 1.68%,
    #1168f8 97.37%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    86.46deg,
    #229afa1a 7.85%,
    #1168f81a 97.37%
  );
  --uw-color-accent: var(--uw-color-secondary-text);
  --uw-color-accent-gradient: linear-gradient(
    86.72deg,
    #818dff 1.55%,
    #6472f1 98.45%
  );
  --uw-color-accent-gradient-light: linear-gradient(
    104.48deg,
    #818dff1a 1.55%,
    #6472f11a 98.45%
  );
  --uw-color-number-gradient: linear-gradient(
    #fff 0%,
    transparent 20%,
    transparent 80%,
    #fff 100%
  );

  --uw-sm-border-radius: 4px;

  --uw-border-radius: 10px;
  --uw-border-color: var(--uw-color-border);
  --uw-color-warn: #f03a01;
  --uw-color-accent: --theme-accent-500;
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-warn-shade: rgb(252, 216, 204);
  --uw-color-danger: #f03a01;

  // cards
  --uw-card-background-color: var(--uw-surface-primary) !important;
  --uw-card-title-color: var(--uw-color-neutral-primary-text);
  --uw-card-box-shadow: 0 14px 40px rgba(47, 93, 182, 0.1);
  --mdc-elevated-card-container-shape: var(--uw-border-radius);
  --mdc-outlined-card-outline-color: transparent;

  // tabs
  --uw-tab-color: var(--uw-color-white);
  --uw-tab-active-color: var(--uw-color-primary-text);
  --uw-tab-label-color: var(--uw-color-neutral-secondary-text);

  // toolbar
  --uw-toolbar-border-color: var(--uw-color-border);
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // inputs
  --uw-input-radius: 6px;
  --uw-input-error-color: #f03a01;
  --uw-disabled-inputs: #f4f6f9;
  --uw-disable-input-text-color: #5f626f;
  --uw-disabled-field-color: var(--uw-disabled-inputs);
  --uw-input-text-color: var(--uw-color-tertiary-text-icons);
  --uw-input-text-filled-color: var(--uw-color-filled-text);
  --uw-input-border-color: var(--uw-border-color);
  --uw-input-number-bg: #e8f1fe;
  --uw-input-untransparent-bg: #f5f5f5;
  --uw-tag-background: #f0f2f5;
  --uw-tag-color: #5f626f;
  --uw-star-rating-input: #ffc545;

  // buttons
  --uw-fab-button-color: var(--uw-color-white);
  --uw-disable-button-color: rgba(0, 0, 0, 0.26);
  --uw-raised-button-color: var(--uw-color-white);

  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: #229afa52;
  --uw-button-elevation-primary-color-3: #6472f11a;

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: #818dff52;
  --uw-button-elevation-accent-color-3: #6472f11a;

  --uw-button-elevation-warn-color-1: #0003;
  --uw-button-elevation-warn-color-2: #f03a0152;
  --uw-button-elevation-warn-color-3: #f5754d1a;

  --uw-button-primary-color: var(--uw-color-primary-text);
  --uw-button-accent-color: var(--uw-color-secondary-text);

  --uw-button-border-radius: 40px;
  --uw-button-text-color: inherit;
  --uw-button-toggle-bg: #f6f8fb;
  --mdc-protected-button-label-text-color: #fff;

  // text
  --uw-text-primary: var(--uw-color-neutral-primary-text);
  --uw-text-secondary: var(--uw-color-neutral-secondary-text);
  --uw-text-color: var(--uw-color-neutral-secondary-text);
  --uw-text-upper: uppercase;
  --uw-text-background: #fcfdfe;

  // divider
  --uw-divider-color: var(--uw-color-border);

  // icons
  --uw-icon-color: var(--uw-color-tertiary-text-icons);
  --uw-text-icon-default: var(--uw-color-tertiary-text-icons);
  --uw-icon-bg: var(--uw-color-background);

  // list
  --uw-list-item-bg-hover: var(--uw-color-background);
  --uw-selected-list-color: rgba(249, 250, 253, 0.92);
  --uw-list-text-color: var(--uw-color-neutral-secondary-text);

  // drawer
  --uw-disabled-toggle: rgba(59, 59, 59, 0.3);
  --uw-drawer-border: #e2e3e3;

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: rgba(0, 0, 0, 0.87);
  --uw-datepicker-disabled-text-color: rgba(0, 0, 0, 0.38);
  --uw-datepicker-arrow: rgba(0, 0, 0, 0.54);
  --uw-datepicker-border: rgba(0, 0, 0, 0.12);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // dialog
  --uw-dialog-card-background-color: var(--uw-color-white);
  --uw-dialog-box-shadow: 0 10px 30px rgba(47, 93, 182, 0.08);
  --mdc-dialog-container-shape: var(--uw-border-radius);

  // stepper
  --uw-step-header-box-shadow: 0 7px 14px #2f5db633;

  // links
  --uw-link-primary-color: var(--uw-color-primary-text);
  --uw-link-accent-color: var(--uw-color-secondary-text);

  // table
  --uw-table-color: var(--uw-color-white);
  --uw-table-head-text-color: var(--uw-color-tertiary-text-icons);
  --uw-table-body-text-color: var(--uw-color-neutral-secondary-text);

  // pagination
  --uw-pagination-arrow: rgba(0, 0, 0, 0.87);
  --uw-pagination-disabled-arrow: rgba(0, 0, 0, 0.38);

  // ribbon
  --uw-ribbon-color: var(--uw-color-white);

  // ng fix
  --mat-card-title-text-font: "Poppins";
  --mat-card-subtitle-text-font: "Poppins";

  // Material System
  --mat-sys-primary: var(--uw-color-primary-text);
  --mat-sys-error: var(--uw-color-danger);
  --mat-sys-outline: var(--uw-color-border);
  --mat-sys-surface: var(--uw-surface-primary);
}
