@use "sass:map";
@tailwind utilities;

$warning: #e57d02;
$success: #1e9f96;
$error: #e43e0a;
$blank: #fff;
$success-light: #5fcb9c;
$colors: () !default;
$colors: map.merge(
  (
    warning: $warning,
    success: $success,
    error: $error,
    blank: $blank,
    success-light: $success-light,
  ),
  $colors
);

[bg-color="primary"] {
  background-color: var(--uw-color-primary) !important;
}

[bg-color="primary-gradient"] {
  background: var(--uw-color-primary-gradient);
}

.bg-color-primary {
  background-color: var(--uw-color-primary);
}

.bg-color-muted {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(50, 50, 50, 0.2) !important;
}

.bg-color-primary-10 {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(var(--uw-color-primary-rgb), 0.1) !important;
}

.bg-color-primary-20 {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(var(--uw-color-primary-rgb), 0.2) !important;
}

.bg-color-primary-50 {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(var(--uw-color-primary-rgb), 0.5) !important;
}

.bg-color-warn {
  background-color: rgb(var(--uw-color-warn-rgb), 1) !important;
}

.bg-color-warn-5 {
  background-color: rgb(var(--uw-color-warn-rgb), 0.05) !important;
}

.bg-color-warn-10 {
  background-color: rgb(var(--uw-color-warn-rgb), 0.1) !important;
}

.bg-color-accent {
  background-color: rgb(var(--uw-color-accent-rgb));
}

.bg-color-accent-10 {
  /* stylelint-disable-next-line color-function-notation */
  background-color: rgba(var(--uw-color-accent-rgb), 0.1) !important;
}

.bg-color-accent-gradient {
  background: var(--uw-color-accent-gradient);
}

.bg-color-primary-gradient-light {
  background: var(--uw-color-primary-gradient-light) !important;
}

.bg-color-surface-primary {
  background-color: var(--uw-surface-primary) !important;
}

.bg-color-primary-gradient {
  background: var(--uw-color-primary-gradient) !important;
}

.bg-color-background {
  background-color: var(--uw-background-color);
}

.bg-primary-tone {
  background: rgb(var(--uw-color-primary-text) 0.1);
}

.border-primary {
  border-color: var(--uw-color-primary) !important;
}

.border-primary-50 {
  /* stylelint-disable-next-line color-function-notation */
  border-color: rgb(var(--uw-color-primary-rgb), 0.5) !important;
}

.border-warn {
  border-color: var(--uw-color-warn) !important;
}

.border-default {
  border-color: var(--uw-border-color) !important;
}

@layer utilities {
  @each $style, $color in $colors {
    .color--#{$style} {
      color: $color !important;

      path {
        fill: $color !important;
      }
    }
  }

  @each $style, $color in $colors {
    .bg--#{$style} {
      background-color: rgba($color, 1) !important;
    }

    .bg--#{$style}-tone {
      background-color: rgba($color, 0.1) !important;
    }
  }

  .border-primary {
    border-color: var(--uw-color-primary) !important;
  }

  .bg-color-warn-10 {
    background-color: rgb(var(--uw-color-warn-rgb), 0.1) !important;
  }
}

.border-bg-color {
  border-color: var(--uw-background-color);
}

.color-default {
  color: var(--uw-icon-color);
}

.color-primary {
  color: var(--uw-color-primary) !important;
}

.color-warn {
  color: var(--uw-color-warn) !important;
}

.color-primary-gradient {
  color: var(--uw-color-primary-gradient);
}

.color-neutral-secondary-text {
  color: var(--uw-color-neutral-secondary-text);
}

.color-neutral-primary-text {
  color: var(--uw-color-neutral-primary-text);
}

.color-tertiary-text {
  color: var(--uw-color-tertiary-text-icons);
}

.color-white {
  color: var(--uw-color-white);
}
