@use "../../functions";

.light-red {
  --theme-primary-50: #fee9e4;
  --theme-primary-100: #fcc8bc;
  --theme-primary-200: #faa390;
  --theme-primary-300: #f77e63;
  --theme-primary-400: #f66241;
  --theme-primary-500: #f44620;
  --theme-primary-600: #f33f1c;
  --theme-primary-700: #f13718;
  --theme-primary-800: #ef2f13;
  --theme-primary-900: #ec200b;
  --theme-primary-A100: #ffffff;
  --theme-primary-A200: #ffe5e3;
  --theme-primary-A400: #ffb5b0;
  --theme-primary-A700: #ff9d96;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #feefe4;
  --theme-accent-100: #fed8bd;
  --theme-accent-200: #fdbe91;
  --theme-accent-300: #fca464;
  --theme-accent-400: #fb9143;
  --theme-accent-500: #fa7d22;
  --theme-accent-600: #f9751e;
  --theme-accent-700: #f96a19;
  --theme-accent-800: #f86014;
  --theme-accent-900: #f64d0c;
  --theme-accent-A100: #ffffff;
  --theme-accent-A200: #fff1ed;
  --theme-accent-A400: #ffcaba;
  --theme-accent-A700: #ffb7a1;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000;
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #ffffff;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #f44620;
  --uw-color-primary-rgb: #{functions.hex2rgb(#f44620)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#fa7d22)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(
    164.58deg,
    #ff6853 0%,
    #f53b3b 100%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    168.26deg,
    rgba(255, 104, 83, 0.15) -0.02%,
    rgba(246, 63, 62, 0.15) 99.9%
  );
  --uw-color-accent: #fa7d22;
  --uw-color-accent-gradient: linear-gradient(90deg, #fa9334 0%, #ff7f48 100%);
  --uw-color-accent-gradient-light: linear-gradient(
    90deg,
    rgba(253, 154, 99, 0.15) 0%,
    rgba(249, 153, 68, 0.15) 100%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(244, 70, 32, 0.11);
  --uw-button-elevation-primary-color-3: rgba(244, 70, 32, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(250, 125, 34, 0.11);
  --uw-button-elevation-accent-color-3: rgba(250, 125, 34, 0.3);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);
}
