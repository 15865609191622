@font-face {
  font-family: 'idc-icon-font';
  src:
    url('/assets/fonts/idc-icon-font/idc-icon-font.ttf') format('truetype'),
    url('/assets/fonts/idc-icon-font/idc-icon-font.woff') format('woff'),
    url('/assets/fonts/idc-icon-font/idc-icon-font.svg#idc-icon-font')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.idc-icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'idc-icon-font' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5rem;
  height: 1em !important;
  width: 1em !important;
  /*
  * Fixes rendering issue on safari
  * needs further investigation; might be caused by the idc icons view box
  */
  text-align: left;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* maybe not needed if icons viewbox is fixed, check app selection in isa icons (select context on customer) */
.mat-mdc-unelevated-button > .mat-icon {
  padding: 2px 0;
}
