@use "../../functions";

.light-green {
  --theme-primary-50: #e4f4ee;
  --theme-primary-100: #bbe4d4;
  --theme-primary-200: #8ed3b7;
  --theme-primary-300: #60c19a;
  --theme-primary-400: #3eb385;
  --theme-primary-500: #1ca66f;
  --theme-primary-600: #199e67;
  --theme-primary-700: #14955c;
  --theme-primary-800: #118b52;
  --theme-primary-900: #097b40;
  --theme-primary-A100: #abffcf;
  --theme-primary-A200: #78ffb2;
  --theme-primary-A400: #45ff95;
  --theme-primary-A700: #2bff86;

  --theme-primary-contrast-50: #000000;
  --theme-primary-contrast-100: #000000;
  --theme-primary-contrast-200: #000000;
  --theme-primary-contrast-300: #000000;
  --theme-primary-contrast-400: #000000;
  --theme-primary-contrast-500: #ffffff;
  --theme-primary-contrast-600: #ffffff;
  --theme-primary-contrast-700: #ffffff;
  --theme-primary-contrast-800: #ffffff;
  --theme-primary-contrast-900: #ffffff;
  --theme-primary-contrast-A100: #000000;
  --theme-primary-contrast-A200: #000000;
  --theme-primary-contrast-A400: #000000;
  --theme-primary-contrast-A700: #000000;

  --theme-accent-50: #f1f8e8;
  --theme-accent-100: #dcefc7;
  --theme-accent-200: #c5e4a1;
  --theme-accent-300: #aed97b;
  --theme-accent-400: #9cd05f;
  --theme-accent-500: #8bc843;
  --theme-accent-600: #83c23d;
  --theme-accent-700: #78bb34;
  --theme-accent-800: #6eb42c;
  --theme-accent-900: #5ba71e;
  --theme-accent-A100: #edffe0;
  --theme-accent-A200: #cfffad;
  --theme-accent-A400: #b1ff7a;
  --theme-accent-A700: #a2ff60;

  --theme-accent-contrast-50: #000000;
  --theme-accent-contrast-100: #000000;
  --theme-accent-contrast-200: #000000;
  --theme-accent-contrast-300: #000000;
  --theme-accent-contrast-400: #000000;
  --theme-accent-contrast-500: #000000;
  --theme-accent-contrast-600: #000000;
  --theme-accent-contrast-700: #000000;
  --theme-accent-contrast-800: #000000;
  --theme-accent-contrast-900: #000000;
  --theme-accent-contrast-A100: #000000;
  --theme-accent-contrast-A200: #000000;
  --theme-accent-contrast-A400: #000000;
  --theme-accent-contrast-A700: #000000;

  --theme-warn-50: #fde7e1;
  --theme-warn-100: #fbc4b3;
  --theme-warn-200: #f89d80;
  --theme-warn-300: #f5754d;
  --theme-warn-400: #f25827;
  --theme-warn-500: #f03a01;
  --theme-warn-600: #ee3401;
  --theme-warn-700: #ec2c01;
  --theme-warn-800: #e92501;
  --theme-warn-900: #e51800;
  --theme-warn-A100: #fff;
  --theme-warn-A200: #ffdbd9;
  --theme-warn-A400: #ffaba6;
  --theme-warn-A700: #ff938c;

  --theme-warn-contrast-50: #000;
  --theme-warn-contrast-100: #000;
  --theme-warn-contrast-200: #000;
  --theme-warn-contrast-300: #000;
  --theme-warn-contrast-400: #000;
  --theme-warn-contrast-500: #fff;
  --theme-warn-contrast-600: #fff;
  --theme-warn-contrast-700: #fff;
  --theme-warn-contrast-800: #fff;
  --theme-warn-contrast-900: #fff;
  --theme-warn-contrast-A100: #000;
  --theme-warn-contrast-A200: #000;
  --theme-warn-contrast-A400: #000;
  --theme-warn-contrast-A700: #000;

  --uw-background-color: #f9fafd;
  --uw-color-white: #fff;
  --uw-color-primary: #1ca66f;
  --uw-color-primary-rgb: #{functions.hex2rgb(#1ca66f)};
  --uw-color-accent-rgb: #{functions.hex2rgb(#8bc843)};
  --uw-color-warn-rgb: #{functions.hex2rgb(#f03a01)};
  --uw-color-primary-gradient: linear-gradient(
    86.72deg,
    #74d19d 1.55%,
    #4ac59b 98.45%
  );
  --uw-color-primary-gradient-light: linear-gradient(
    85.64deg,
    rgba(114, 209, 157, 0.15) 1.55%,
    rgba(77, 198, 155, 0.15) 98.45%
  );
  --uw-color-accent: #8bc843;
  --uw-color-accent-gradient: linear-gradient(90deg, #b2e971 0%, #9dd75a 100%);
  --uw-color-accent-gradient-light: linear-gradient(
    90deg,
    rgba(178, 233, 113, 0.15) 0%,
    rgba(157, 215, 90, 0.15) 100%
  );
  --uw-color-primary-text: #1b84f9;
  --uw-color-secondary-text: #21abd9;
  --uw-color-neutral-primary-text: #313134;
  --uw-color-neutral-secondary-text: #5f626f;
  --uw-color-tertiary-text-icons: #a3b1cd;
  --uw-border-color: #e5e9f0;
  --uw-color-disable: #5f626f;

  // buttons
  --uw-button-elevation-primary-color-1: #0003;
  --uw-button-elevation-primary-color-2: rgba(28, 166, 111, 0.11);
  --uw-button-elevation-primary-color-3: rgba(28, 166, 111, 0.3);

  --uw-button-elevation-accent-color-1: #0003;
  --uw-button-elevation-accent-color-2: rgba(139, 200, 67, 0.11);
  --uw-button-elevation-accent-color-3: rgba(139, 200, 67, 0.3);

  // toolbar
  --uw-toolbar-color: var(--uw-color-tertiary-text-icons);

  // list
  --uw-list-item-bg-hover: rgba(163, 177, 205, 0.08);

  // text
  --uw-list-text-color: var(--uw-color-neutral-primary-text);
  --uw-text-background: var(--uw-background-color);

  // tabs
  --uw-tab-active-color: var(--uw-color-primary);

  // datepicker
  --uw-datepicker-bg-color: var(--uw-color-white);
  --uw-datepicker-text-color: var(--uw-color-neutral-primary-text);
  --uw-datepicker-disabled-text-color: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-arrow: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-border: var(--uw-color-neutral-secondary-text);
  --uw-datepicker-selected-text-color: var(--uw-color-white);

  // links
  --uw-link-primary-color: var(--uw-color-primary);
  --uw-link-accent-color: var(--uw-color-accent);
}
